<script>

	import { setContext } from 'svelte';
	import { slide, fade } from 'svelte/transition';

	import dayjs from "dayjs";
	import utc from "dayjs/plugin/utc";
	dayjs.extend(utc);

	import { screens, currentScreen, displayLang, sitemap, ticketTypes, attendee, displayAmounts, invitee, amountsDebounce, taxID, bookingCountry, bookingSCP, bookingPostcode, simplifiedFlow } from '../stores.js';
	import { getTextFromDict } from '../i18n.js';

	import { validateField } from '../validation.js';
	import { getAmounts } from '../amounts.js';

	import Field from './Field.svelte';

	import ProgressBar from './display/ProgressBar.svelte';
	import SubmitButton from './display/SubmitButton.svelte';
	import Divider from './display/Divider.svelte';
	import TextBlock from './display/TextBlock.svelte';
	import RegistrationSummary from './display/RegistrationSummary.svelte';
	import BigTick from './display/BigTick.svelte';
	import TicketType from './fields/TicketType.svelte';

	export let component;
	export let componentIndex;
	export let responseSet;
	export let responseSetIndex;

	setContext('responseSetRef', responseSet.ref);

	let isRunOn = false;
	let problem = false;

	function isYetToCome(date) {
		let dt = dayjs.utc(date);
		let now = dayjs.utc();
		return dt.isAfter(now) ? true : false;
	}

	function checkConstraints() {
		if ((responseSet.deleted) && (component.component != 'ProgressBar')) {

			component.shown = false;

		} else if ($simplifiedFlow && component.omitFromSimplifiedFlow) {

			component.shown = false;

		} else if (component.constraints && component.constraintConditions && component.constraintConditions.length) {

			let met = 0;

			for (const cc of component.constraintConditions) {

				// console.log(cc);

				if (cc.field == '_page') {

					switch(cc.type) {
						case 'is_published':
							for (const p of $sitemap) {
								if (p.ref == cc.value) {
									if (p.published) {
										met++;
									}
									break;
								}
							}
							break;
						case 'is_not_published':
							for (const p of $sitemap) {
								if (p.ref == cc.value) {
									if (!p.published) {
										met++;
									}
									break;
								}
							}
							break;
					}

				} else if (cc.field == '_registration') {

					switch(cc.type) {

						case 'is_new':
							if (!$attendee) {
								met++;
							}
							break;
						case 'is_edited':
							if ($attendee) {
								met++;
							}
							break;

						case 'is_invitee':
							if ($invitee) {
								met++;
							}
							break;
						case 'is_not_invitee':
							if (!$invitee) {
								met++;
							}
							break;

						case 'is_free':
							if (!$displayAmounts.netAmount) {
								met++;
							}
							break;
						case 'requires_payment':
							if ($displayAmounts.netAmount) {
								met++;
							}
							break;

						case 'is_paid':
							// TODO: make sure this still works when we have different payment amounts
							if ($displayAmounts.amountPaid) {
								met++;
							}
							break;
						case 'is_not_paid':
							// TODO: make sure this still works when we have different payment amounts
							if (!$displayAmounts.amountPaid) {
								met++;
							}
							break;

					}

				} else if (cc.field == '_date') {

					switch(cc.type) {
						case 'is_before':
							if (isYetToCome(cc.value)) {
								met++;
							}
							break;
						case 'is_after':
							if (!isYetToCome(cc.value)) {
								met++;
							}
							break;
					}

				} else if (cc.field == '_environment') {

					switch(cc.type) {
						case 'is_website':
							met++;
							break;
						case 'is_staging':
							if ((document.getElementById('aznr') && document.getElementById('aznr').dataset.staging && document.getElementById('aznr').dataset.staging == 'staging')) {
								met++;
							}
							break;
						case 'is_platform':
							break;
					}

				} else {

					const v = getValue(cc.field);
					const t = getTicketType();

					// console.log(cc.field, v);

					if ((v !== undefined) && (v !== null) && (v !== '') && (((Array.isArray(v)) && (v.length > 0)) || (typeof v == 'string') && (v.trim().length !== 0))) {
						switch(cc.type) {
							case 'is_set':
								met++;
								break;
							case 'equals':
								if (cc.value == v) {
									met++;
								}
								break;
							case 'contains':
								if (v.toString().toLowerCase().includes(cc.value.toString().toLowerCase())) {
									met++;
								}
								break;
							case 'includes':
								if (v.includes(cc.value)) {
									met++;
								}
								break;

							// NOTE: these two are now deprecated in favour of _registration conditions
							case 'is_free':
								if (t && !t.price) {
									met++;
								}
								break;
							case 'requires_payment':
								if (t && t.price) {
									met++;
								}
								break;

							case 'offers_payment_options':
								if (t && t.paymentOptions && (t.paymentOptions.length > 1)) {
									met++;
								}
								break;

							case 'is_earlybird':
								if (t && t.earlybirdPrice && (!t.earlybirdDeadline || isYetToCome(t.earlybirdDeadline))) {
									met++;
								}
								break;

							case 'has_preapproval':
								if (t && t.preapproval) {
									met++;
								}
								break;

							case 'has_wait_list':
								if (t && t.waitlist) {
									met++;
								}
								break;

							case 'is_virtual':
								if (t && t.virtualOnly) {
									met++;
								}
								break;

						}
					}

				}

				if (met && (component.constraintLogic == 'any')) {
					break;
				}

			}

			let pass = (component.constraintLogic == 'all') ? (met == component.constraintConditions.length) : (met > 0);

			if (component.constraintEffect == 'show') {
				component.shown = pass;
				component.disabled = false;
			} else if (component.constraintEffect == 'hide') {
				component.shown = !pass;
				component.disabled = false;
			} else if (component.constraintEffect == 'disable') {
				component.shown = true;
				component.disabled = pass;
			}

			// console.log(component.component, component.constraintEffect, met, component.constraintConditions.length, pass, component.shown, component.disabled);

			if (!component.shown) {
				component.value = undefined;
			}

			// console.log(c.label + ' / ' + met + ' / ' + pass + ' / ' + c.shown);

		} else {

			if (component.component == 'ProgressBar') {
				if (responseSetIndex == 0) {
					component.shown = true;
				} else {
					component.shown = false;
				}
			} else {
				component.shown = true;
			}

		}
	}

	function checkInviteePreset() {
		if (component.inviteePreset != undefined) {
			const tt = getTicketType();
			component.disabled = (tt && tt.transferable) ? false : true;
			if (component.disabled) {
				component.value = component.inviteePreset;
			}
		}
	}

	function getTicketType() {
		let ttref;
		for (const c of $screens[0].components) {
			if (c.component == 'TicketType') {
				for (const r of $screens[0].responseSets[0].components) {
					if (r.ref == c.ref) {
						ttref = r.value;
					}
				};
			}
		}
		if (ttref) {
			for (const t of $ticketTypes) {
				if (t.ref == ttref) {
					return t;
				}
			}
		}
	}

	function getValue(ref) {
		// for (const c of $screens[$currentScreen].responseSets[responseSetIndex].components) {
		// 	if (c.ref == ref) {
		// 		return c.value;
		// 	}
		// };
		for (const s of $screens) {
			if (s.responseSets && s.responseSets.length) {
				const rs = ($currentScreen == (s.num - 1)) ? responseSetIndex : 0;
				for (const c of s.responseSets[rs].components) {
					if (c.ref == ref) {
						return c.value;
					}
				}
			}
		}
	}

	function checkLabels() {
		if (component.component == 'TicketType') return false;
		if ($screens[$currentScreen].components[componentIndex-1]) {
			let labelText = getTextFromDict(component.label,$displayLang);
			let prevLabelText = getTextFromDict($screens[$currentScreen].components[componentIndex-1].label,$displayLang);
			if (labelText && prevLabelText && (labelText == prevLabelText)) {
				return true;
			}
		}
		return false;
	}

	async function checkField(event) {
		await validateField(component, event);
		component = component; // trigger DOM update
		if (!problem) checkAmounts(event);
	}

	async function checkAmounts(event) {
		if (event && event.type == 'input') return true; // only recalc on blur
		if (component.component == 'DiscountCode') return true; // this does its own getAmounts
		if (component.component == 'TaxID') return true; // this does its own getAmounts
		if (component.component == 'MembershipNumber') return true; // this does its own getAmounts
		clearTimeout($amountsDebounce);
		if (component.hook == 'bookingSCP') $bookingSCP = component.value;
		if (component.hook == 'bookingPostcode') $bookingPostcode = component.value;
		if (component.hook == 'bookingCountry') $bookingCountry = component.value;
		if ($currentScreen == 2) {
			$amountsDebounce = setTimeout(async () => {
				await getAmounts();
			}, 200);
		}
		return true;
	};

	$: if (component) {
		checkInviteePreset();
		checkConstraints();
		isRunOn = checkLabels();
	}

	$: if (component.validationStatus) {
		problem = (component.validationStatus.type == 'error') ? true : false;
	}

</script>

{#if component.shown}
	<div
		id="azn_c_{component.ref}"
		class="azn_component {component.type} {component.component}"
		class:isRunOn
		class:problem
		transition:slide|local={{duration: (component.component == 'ProgressBar') ? 0 : 400}}
	>
		{#if component.component == 'Divider'}
			<Divider {component}/>
		{:else if component.component == 'TextBlock'}
			<TextBlock {component}/>
		{:else if component.component == 'ProgressBar'}
			<ProgressBar/>
		{:else if component.component == 'SubmitButton'}
			<SubmitButton {component} {responseSetIndex} on:adjunct/>
		{:else if component.component == 'TicketType'}
			<TicketType bind:field={component}/>
		{:else if component.component == 'RegistrationSummary'}
			<RegistrationSummary {component}/>
		{:else if component.component == 'BigTick'}
			<BigTick {component} />
		{:else}
			<Field bind:field={component} {responseSet} on:input={checkField} on:change={checkField} on:blur={checkField} on:set={checkField}/>
		{/if}
	</div>
{/if}