<script>

	// Would isDecimal be better (allows decimal point locale) ..?
	import isNumeric from 'validator/es/lib/isNumeric';

	import { displayLang } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let field;

	let name = field.name ? field.name : field.ref;

	let inputmode = 'decimal';

	if (!field.validationCallback) {
		field.validationCallback = async (event) => {

			if (event && event.type == 'input') {
				return true;
			}

			if (field.value) {
				if (!isNumeric(field.value)) {
					const message = 'Please enter a valid number.'; // TODO: i18n
					field.validationStatus = {
						type: "error",
						message: message
					};
					return false;
				}
			}

		};
	}

</script>

<input
	type="text"
	id="azn_i_{name}"
	{name}
	bind:value="{field.value}"
	placeholder="{getTextFromDict(field.placeholder, $displayLang)}"
	required="{field.required ? 'required' : ''}"
	{inputmode}
	on:input
	on:blur
	disabled={field.disabled}
/>
