<script>

	import { onDestroy } from 'svelte';
	// import { tweened } from 'svelte/motion';

	import dayjs from "dayjs";
	import utc from "dayjs/plugin/utc";
	dayjs.extend(utc);

	import { timeoutRelease, bigError } from '../../stores.js';

	let release = dayjs.utc($timeoutRelease);
	let now = dayjs.utc();

	let remaining = release.diff(now, 'second');

	// console.log($timeoutRelease, remaining);

	// let timer = tweened(remaining);

	let ivl = setInterval(() => {
		if (remaining > 0) {
			remaining--;
		}
		if (!(remaining > 0)) {
			clearInterval(ivl);
			$bigError = 'Timeout';
		}
	}, 1000);

	let hours, minutes, seconds;

	$: if (remaining) {
		calcHMS();
	}

	function calcHMS() {
		hours = String(Math.floor(remaining / 60 / 60));
		minutes = String(Math.floor(remaining / 60)).padStart(2, '0');
		seconds = String(Math.floor(remaining - minutes * 60)).padStart(2, '0');
	}

	$: if ($bigError) {
		clearInterval(ivl);
		$timeoutRelease = null;
	}

	onDestroy(() => {
		clearInterval(ivl);
	});

</script>

<!-- <style>
	:global(#aznr) .timer {
		color: var(--boxBorderFocusColor);
		right: 0;
		top:  0;
		opacity: 1;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 2rem;
		margin: 0;
	}
	:global(#aznr) .timer span {
		font-family: system-ui;
		font-variant-numeric: tabular-nums;
	}
</style> -->

{#if !(hours > 0)}
	<p class="timer"><span>{minutes}:{seconds}</span></p>
{/if}
