import { writable, derived } from 'svelte/store';

export const eventToken = writable(null);

export const busy = writable(false);
export const bigError = writable(null);

export const displayLang = writable('EN');

export const event = writable(null);

// export const eventLocale = writable('en-GB');
// export const currency = writable('USD');

export const formToken = writable(null);

// export const attendeeToken = writable(undefined);
// export const inviteToken = writable(undefined);

export const invitee = writable(null);

export const attendeeToken = writable(null);
export const attendee = writable(null);

// export const formStatus = writable('initialised');

export const boxWidth = writable(0);
export const boxHeight = writable('auto');

export const screens = writable([]);
export const currentScreen = writable(null);
export const allowAutoscroll = writable(true);

export const validationErrors = writable([]);
// export const helpTooltip = writable(null);

export const ticketTypes = writable([]);
export const crmFields = writable([]);
export const sitemap = writable(null);

export const countryOpts = writable([]);
export const clientCountry = writable(null);
export const socialMediaServiceOpts = writable([]);

export const timeoutRelease = writable(null);

export const preapprovalStatus = writable(null);
export const waitlistStatus = writable(null);

// Only set after screen 1
export const ticketType = writable(null);
export const inventory = writable(null);

export const displayAmounts = writable(null);
export const paymentAmount = writable(0);

// used to update amounts
export const amountsDebounce = writable(null);
export const discountCode = writable('');
export const taxID = writable('');
export const bookingCountry = writable('');
export const bookingSCP = writable('');
export const bookingPostcode = writable('');

export const simplifiedFlow = writable(false);
