<script>

	// http://localhost:5000/#/azn/f/EF4cJ5nTS6YXuiPtenW9NQ

	import { onMount, tick, setContext } from 'svelte';
	import { fade, slide } from 'svelte/transition';

	import * as animateScroll from "svelte-scrollto";

	import Spinner from './ui/Spinner.svelte';
	import BigError from './ui/BigError.svelte';
	import InviteeResponse from './ui/InviteeResponse.svelte';
	import FormSet from './form/FormSet.svelte';

	import { eventToken, formToken, screens, currentScreen, busy, bigError, boxWidth, boxHeight, displayLang, displayAmounts, ticketTypes, crmFields, event, sitemap, invitee, attendeeToken, attendee, timeoutRelease, ticketType, inventory, preapprovalStatus, waitlistStatus, simplifiedFlow, allowAutoscroll } from './stores.js';

	import { getAuth, getServerData, postServerData } from './prelude.js';
	import { colorMix, isDark, getErrorColor } from './color.js';

	export let checkinAttendee = null; // only set in checkin context, is a store
	setContext('checkinAttendee', checkinAttendee);

	const bp_superwide = 1100;
	const bp_wide = 900;
	const bp_mid = 680;

	let authExpired = false;
	let bh;

	$: if ($busy) {
		clearTimeout(bh);
	}

	$: if ($boxHeight != 'auto') {
		unsetBoxHeight();
	}

	function unsetBoxHeight() {
		clearTimeout(bh);
		bh = setTimeout(function(){
			$boxHeight = 'auto';
		}, 800);
	}

	let mounted = false;

	let cardColor = '#fdfdfd';
	let boxBorderColor = '#e1e1e1';
	let boxBorderFocusColor = '#888888';
	let boxDisabledColor = '#efefef';
	let highlightColor = '#eeeeee';

	let inviteeToken;
	let inviteeResponse;

	let pwResponse;

	// console.log('initialising App...');
	screens.set([]); // allow re-initialisation
	formToken.set(null); // likewise

	let appElement;

	onMount(async () => {

		// 20 Dec 2022 -- is checkin localstorage available here?
		// console.log('check-in unlock',localStorage.getItem('unlock'));
		// -- yes! that works!
		// so: we can adopt offline mode if:
		// a. the window hostname is checkin.attendzen.io
		// b. there's a reg formdef + event setup in localstorage
		// c. we're offline
		// -- offline mode adds registrations to IndexedDB
		// -- only the checkin app itself syncs them,
		//    so offline mode here is useless outside of that context
		// 03 Aug 2023 -- we now pass a checkinAttendee prop...
		// 04 Dec 2023 -- enable simplifiedFlow

		appElement = document.getElementById('aznr');

		eventToken.set(appElement.dataset.event);

		await getAuth();

		if (!$bigError) {

			let hash = location.hash;
			let hashItems = hash.split('/');

			let paymentToken;

			let scrollOnMount = false;

			if ((hashItems[1] == 'azn') && (hashItems[2] == 'a') && (hashItems[3])) {
				// editing attendee
				$attendeeToken = hashItems[3];
				scrollOnMount = true;

			} else if ((hashItems[1] == 'azn') && (hashItems[2] == 'i') && (hashItems[3])) {
				// responding to invite (accept)
				inviteeToken = hashItems[3];
				scrollOnMount = true;
				console.log('invitee accept', inviteeToken);

			} else if ((hashItems[1] == 'azn') && (hashItems[2] == 'd') && (hashItems[3])) {
				// responding to invite (decline)
				inviteeToken = hashItems[3];
				scrollOnMount = true;
				inviteeResponse = 'Decline';
				// console.log('invitee decline', inviteeToken);

			} else if ((hashItems[1] == 'azn') && (hashItems[2] == 'm') && (hashItems[3])) {
				// responding to invite
				inviteeToken = hashItems[3];
				scrollOnMount = true;
				inviteeResponse = 'Maybe';
				// console.log('invitee maybe', inviteeToken);

			} else if ((hashItems[1] == 'azn') && (hashItems[2] == 'p') && (hashItems[3])) {
				// back from payment
				paymentToken = hashItems[3];
				scrollOnMount = true;

			} else if ((hashItems[1] == 'azn') && (hashItems[2] == 'x') && (hashItems[3])) {
				// responding to preapproval or waitlist (decline)
				formToken.set(hashItems[3]);
				scrollOnMount = true;
				pwResponse = 'Decline';

			}

			setBoxWidth();

			$boxHeight = '160px';

			await tick();
			clearTimeout(bh);

			const staging = appElement.dataset.staging;

			let params = {
				invitee: inviteeToken,
				attendee: $attendeeToken,
				staging: staging
			};

			if ((hashItems[1] == 'azn') && (hashItems[2] == 'f') && (hashItems[3])) {
				formToken.set(hashItems[3]);
				scrollOnMount = true;
			}

			if (paymentToken) {

				const confirmData = await postServerData('/sites/registrations/payments', {
					paymentToken: paymentToken
				});

				if (confirmData.formToken) {
					formToken.set(confirmData.formToken);
				}

			}

			await initForm(params);

			if (!$bigError) {

				const bgColor = getComputedStyle(appElement).getPropertyValue('--bgColor');
				const textColor = getComputedStyle(appElement).getPropertyValue('--textColor');
				const linkColor = getComputedStyle(appElement).getPropertyValue('--linkColor');

				// The higher the value, the more dominant the bgColor
				// On a dark background, we want the bgColor to be *less* dominant, so we need a lower value

				let cardColorMix = 90;
				let boxBorderColorMix = 80;
				let boxBorderFocusColorMix = 40;
				let boxDisabledColorMix = 94;
				let highlightColorMix = 90;

				if (isDark(bgColor)) {
					cardColorMix -= 20;
					boxBorderColorMix -= 20;
					boxBorderFocusColorMix -= 20;
					boxDisabledColorMix -= 20;
					highlightColorMix -= 20;
				}

				cardColor = colorMix(bgColor, textColor, cardColorMix);
				boxBorderColor = colorMix(bgColor, textColor, boxBorderColorMix);
				boxBorderFocusColor = colorMix(bgColor, textColor, boxBorderFocusColorMix);
				boxDisabledColor = colorMix(bgColor, textColor, boxDisabledColorMix);
				highlightColor = colorMix(bgColor, linkColor, highlightColorMix);

				// console.log('mounting app', $currentScreen, scrollOnMount);

				mounted = true;

				if (scrollOnMount) {
					setTimeout(() => {
						scrollToTop();
					}, 600);
				}

				if ((staging == 'staging') && !paymentToken) {
					$bigError = 'Staging';
				}

				// console.log('inviteeResponse',inviteeResponse);
				// console.log('invitee',$invitee);

			}

		}

	});

	$: if (mounted) {
		resetErrorColor();
	}

	function resetErrorColor() {

		// 15 Sep 2021 -- override the component errorColor
		// (if we change theme colors, the error color wouldn't get reset
		// until the component is edited in the site builder)

		if (!($event && $event.colors)) return;

		const bgColor = getComputedStyle(appElement).getPropertyValue('--bgColor');

		const errorColor = getErrorColor([bgColor, $event.colors['bgColor'], $event.colors['textColor'], $event.colors['accent1'], $event.colors['accent2'], $event.colors['accent3'], $event.colors['accent4'], $event.colors['accent5'], $event.colors['accent6']]);

		const appContainer = appElement.parentElement;

		let styles = appContainer.getAttribute('style');

		if (!styles) return;

		const regex = new RegExp(`--errorColor:.+?;`);
		styles = styles.replace(regex, '--errorColor:' + errorColor + ';');
		appContainer.setAttribute('style', styles);

	}

	async function initForm(params, restart) {

		params.lang = $displayLang;
		params.u = localStorage.getItem('azn_u');

		if ($formToken) {
			params.formToken = $formToken;
		}

		const screensData = await getServerData('/sites/registrations', params);

		// only on resumed form, may be provided alongside an error
		if (screensData.preapprovalStatus) {
			preapprovalStatus.set(screensData.preapprovalStatus);
		}

		// only on resumed form, may be provided alongside an error
		if (screensData.waitlistStatus) {
			waitlistStatus.set(screensData.waitlistStatus);
		}

		// console.log('screensData', screensData);

		if ($formToken && $bigError && ($bigError == 'Timeout')) {

			$bigError = null;

			await resetRelease();

			if (!$bigError) {
				await initForm(params);
			}

		} else if ($formToken && $bigError && ($bigError == 'Already-Declined')) {

			$bigError = null;
			pwResponse = 'Decline-Confirmed';

		} else if (!$bigError) {

			if (screensData.event) {
				event.set(screensData.event);
				if (!$event.currency) $simplifiedFlow = true;
			}

			if (screensData.formToken) {
				formToken.set(screensData.formToken);
			}

			if (!restart) { // try to keep existing responseSets
				if (screensData.screens) {
					screens.set(screensData.screens);
				}
			}

			if (screensData.ticketTypes) {
				ticketTypes.set(screensData.ticketTypes);
			}

			if (screensData.attendee) {
				attendee.set(screensData.attendee);
			} else {
				attendeeToken.set(null); // don't keep a dodgy attendeeToken hanging around
			}

			if (screensData.invitee) {
				invitee.set(screensData.invitee);
			}

			if (screensData.crmFields) {
				crmFields.set(screensData.crmFields);
			}

			if (screensData.sitemap) {
				sitemap.set(screensData.sitemap);
			}

			// only on resumed form
			if (screensData.displayAmounts) {
				displayAmounts.set(screensData.displayAmounts);
			}

			// only on resumed form
			if (screensData.timeoutRelease) {
				timeoutRelease.set(screensData.timeoutRelease);
			}

			// only on resumed form
			if (screensData.ticketType) {
				ticketType.set(screensData.ticketType);
			}

			// only on resumed form
			if (screensData.inventory) {
				inventory.set(screensData.inventory);
			}

			if (restart) {
				$screens.forEach((s,i) => {
					$screens[i].status = undefined;
					if ((i == 0) && s.responseSets) {
						for (const c of s.components) {
							if (c.component == 'TicketType') {
								let rsi = 0;
								for (const r of s.responseSets[0].components) {
									if (r.ref == c.ref) {
										$screens[0].responseSets[0].components[rsi].value = undefined;
										break;
									}
									rsi++;
								}
								break;
							}
						}
					}
				});
			} else if ($attendee) {
				$currentScreen = 1;
			} else {
				for (const s of $screens) {
					if (!(s.status && s.status == 'complete')) {
						$currentScreen = s.num - 1;
						break;
					}
				};
			}

			if (!(document.getElementById('aznr') && document.getElementById('aznr').dataset.staging && document.getElementById('aznr').dataset.staging == 'preview')) {
				history.replaceState({ screen: $currentScreen }, null, null);
			}

			// Mostly a check for early bird having ended,
			// but will also cover other price changes
			// Don't show for screen 4 though
			if ($displayAmounts && $ticketType && ($currentScreen != 3)) {
				let ticketPrice = ($ticketType.earlybirdPrice && !$ticketType.earlybirdExpired) ? $ticketType.earlybirdPrice : $ticketType.price;
				if (Number($displayAmounts.ticketPrice) != Number(ticketPrice)) {
					// console.log('Price change detected', Number($displayAmounts.ticketPrice), Number(ticketPrice), $ticketType, $displayAmounts);
					$bigError = 'Price';
				}
			}

			if ($invitee && ($currentScreen != 3)) {
				if ($invitee.status && ($invitee.status == 'Registered') && inviteeResponse) {
				// if ($invitee.status && ($invitee.status == 'Registered')) {
					$bigError = 'Invitee-Already-Accepted';
				} else if ($invitee.status && ($invitee.status == 'Declined')) {
					$bigError = 'Invitee-Already-Declined';
				} else if ($invitee.attendeeCount) {
					// Allow a new booking for the same invitee to go ahead,
					// as long as we're not yet at the max for the ticket type
					let forcedTicketType = $invitee.ticketTypes[0];
					// console.log('prior', forcedTicketType, $invitee.attendeeCount, $ticketTypes);
					for (const [i,o] of $ticketTypes.entries()) {
						if (o.ref == forcedTicketType) {
							console.log('prior', o.maxAttendeesPerBooking, $invitee.attendeeCount);
							if (o.maxAttendeesPerBooking && (parseInt($invitee.attendeeCount) >= parseInt(o.maxAttendeesPerBooking))) {
								$bigError = 'Invitee-Already-Accepted';
							} else if (o.maxAttendeesPerBooking) {
								// Reduce the number available on this booking by the number already made
								$ticketTypes[i].maxAttendeesPerBooking = parseInt(o.maxAttendeesPerBooking) - parseInt($invitee.attendeeCount);
								console.log('(init) maxAttendeesPerBooking is now', $ticketTypes[i].maxAttendeesPerBooking);
							}
							break;
						}
					}
					// Also, switch off cloning from screen 1
					$screens[1].cloneHookedResponseValues = false;
				}
			}

		}

	}

	async function restartForm() {
		$bigError = null;
		mounted = false;
		await initForm({}, true);
		if (!$bigError) {
			mounted = true;
		}
	}

	async function extendTimer() {
		$bigError = null;
		mounted = false;
		await resetRelease();
		if (!$bigError) {
			await initForm({});
			if (!$bigError) {
				mounted = true;
			}
		}
	}

	async function reallocate() {

		$bigError = null;
		mounted = false;

		let quantity = ($screens && $screens[1] && $screens[1].responseSets) ? $screens[1].responseSets.length : 1;

		const inventoryData = await postServerData('/sites/registrations/inventory', {
			formToken: $formToken,
			quantity: quantity,
			ticketType: $ticketType.ref,
			reallocate: true
		});

		if (!$bigError) {

			if (inventoryData.inventory) {
				$inventory = inventoryData.inventory;
			}

			mounted = true;

		}

	}

	async function fixAttendees() {
		$bigError = null;
		$currentScreen = 1;
	}

	async function resetRelease() {

		const releaseData = await postServerData('/sites/registrations/release', {
			formToken: $formToken
		});

		if (!$bigError) {

			if (releaseData.formToken) {
				formToken.set(releaseData.formToken);
			}

			if (releaseData.timeoutRelease) {
				timeoutRelease.set(releaseData.timeoutRelease);
			}

		}

	}

	function setBoxWidth() {
		if (document) {
			let element = document.getElementById('aznr');
			if (element) {
				let positionInfo = element.getBoundingClientRect();
				let width = positionInfo.width;
				boxWidth.set(width);
			}
		}
	}

	function setScreenFromState(e) {
		// console.log('setScreenFromState');
		if (e.state && (e.state.screen != null)) {
			$currentScreen = e.state.screen;
		}
	}

	$: if ($currentScreen) {
		// console.log("currentScreen changed", $currentScreen);
		scrollToTop();
	}

	function scrollToTop() {
		if (mounted && $allowAutoscroll) {
			animateScroll.scrollTo({
				element: '#aznr_app',
				duration: 200,
				offset: -100
			});
			// tick().then(() => { document.getElementById('aznr').scrollIntoView(true) });
		}
	}

	// $: console.log('Box height', $boxHeight);

	let bpClass = 'azn_narrow';
	let lhcw = 0;
	let rhcw = 14;

	$: if ($boxWidth) {
		setBPClass();
	}

	function setBPClass() {
		if ($boxWidth > bp_superwide) {
			bpClass = 'azn_mid azn_wide azn_superwide';
			lhcw = 21;
		} else if ($boxWidth > bp_wide) {
			bpClass = 'azn_mid azn_wide';
			lhcw = 18;
		} else if ($boxWidth > bp_mid) {
			bpClass = 'azn_mid';
		} else {
			bpClass = 'azn_narrow';
		}
	}

	async function inviteeMaybe() {

		const staging = appElement.dataset.staging;

		if (staging) {

			inviteeResponse = 'Maybe-Confirmed';

		} else {

			const responseData = await postServerData('/sites/registrations/maybe', {
				inviteeRef: $invitee.ref
			});

			if (!$bigError) {
				inviteeResponse = 'Maybe-Confirmed';
			}

		}
	}

	async function inviteeDecline() {

		const staging = appElement.dataset.staging;

		if (staging) {

			inviteeResponse = 'Decline-Confirmed';

		} else {

			const responseData = await postServerData('/sites/registrations/decline', {
				inviteeRef: $invitee.ref
			});

			if (!$bigError) {
				inviteeResponse = 'Decline-Confirmed';
			}

		}
	}

	async function waitlistDecline() {

		const staging = appElement.dataset.staging;

		if (staging) {

			pwResponse = 'Decline-Confirmed';

		} else {

			const responseData = await postServerData('/sites/registrations/waitlist-decline', {
				formToken: $formToken
			});

			if (!$bigError) {
				pwResponse = 'Decline-Confirmed';
			}

		}
	}

	async function preapprovalDecline() {

		const staging = appElement.dataset.staging;

		if (staging) {

			pwResponse = 'Decline-Confirmed';

		} else {

			const responseData = await postServerData('/sites/registrations/preapproval-decline', {
				formToken: $formToken
			});

			if (!$bigError) {
				pwResponse = 'Decline-Confirmed';
			}

		}
	}

	function passBackToCheckin() {
		if (checkinAttendee) {
			// console.log('passing back...');
			// if it's defined, it's a store...
			checkinAttendee.set($screens);
		}
	}

	// $: if ($currentScreen) {
	// 	passBackToCheckin();
	// }

	$: if ($currentScreen == 3) {
		passBackToCheckin();
	}

</script>

<svelte:window on:resize={setBoxWidth} on:popstate={setScreenFromState}/>

<svelte:head>
	<!-- <link rel="stylesheet" href={fontImport}/> -->
	<link rel="stylesheet" href="https://sites.attendzen.io/res/display/default/css/aznr.css"/>
	<!-- <link rel="stylesheet" href="https://res.attendzen.io/css/aznr.css"/> -->
	<link rel="stylesheet" href="https://res.attendzen.io/css/textcontent.css"/>
	{#if $event && $event.theme}
		<link rel="stylesheet" href="https://res.attendzen.io/css/{$event.theme}-aznr.css"/>
		<link rel="stylesheet" href="https://res.attendzen.io/css/{$event.theme}-textcontent.css"/>
	{/if}
</svelte:head>

<div
	id="aznr_app"
	class="aznr_app {bpClass}"
	class:transit={$boxHeight == 'auto' ? false : true}
	style="
		height:{$boxHeight};
		--cardColor:{cardColor};
		--boxBorderColor:{boxBorderColor};
		--boxBorderFocusColor:{boxBorderFocusColor};
		--boxDisabledColor:{boxDisabledColor};
		--highlightColor:{highlightColor};
		--lhcw:{lhcw}rem;
		--rhcw:{rhcw}rem;
	"
>

	{#if $bigError && ($bigError != 'Inventory') && ($bigError != 'Tickets')}
		<BigError
			on:extend={extendTimer}
			on:restart={restartForm}
			on:reallocate={reallocate}
			on:fixAttendees={fixAttendees}
		/>
	{:else if $bigError}
		<BigError
			on:extend={extendTimer}
			on:restart={restartForm}
			on:reallocate={reallocate}
			on:fixAttendees={fixAttendees}
		/>
	{:else}
		{#if inviteeResponse && $invitee}
			<InviteeResponse
				{inviteeResponse}
				on:maybe={inviteeMaybe}
				on:decline={inviteeDecline}
			/>
		{:else if pwResponse && $waitlistStatus}
			<InviteeResponse
				inviteeResponse={pwResponse}
				on:decline={waitlistDecline}
			/>
		{:else if pwResponse && $preapprovalStatus}
			<InviteeResponse
				inviteeResponse={pwResponse}
				on:decline={preapprovalDecline}
			/>
		{/if}
	{/if}

	{#if mounted && $screens.length}

		<div id="aznr_app_inner" class="aznr_app_inner">

			{#if $busy}
				<div in:fade|local={{duration:200}}><Spinner size="50" speed="1200" thickness="2" gap="30"/></div>
			{/if}

			{#key $currentScreen}
				<FormSet/>
			{/key}

		</div>

	{:else}

		{#if !$bigError}
			<div style="opacity:0.2"><Spinner size="50" speed="1200" thickness="2" gap="30"/></div>
		{/if}

	{/if}

</div>
