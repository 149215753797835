export const sleep = (ms) => {
	return new Promise(resolve => setTimeout(resolve, ms));
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const autoblur = node => {

   const click = e => {
      e.target.closest('button, a').blur();
   };

   node.addEventListener('click', click);

   return {
      destroy() {
         node.removeEventListener('click', click);
      }
   };

};

export const goHome = () => {
   if (document.getElementById('homelink')) {
      document.getElementById('homelink').click();
   } else {
      window.location = '/';
   }
};

