const tinycolor = require("tinycolor2");

export const colorMix = (color_1, color_2, weight) => {
	// cf. https://gist.github.com/jedfoster/7939513
	function d2h(d) { return d.toString(16); }
	function h2d(h) { return parseInt(h, 16); }
	weight = (typeof(weight) !== 'undefined') ? weight : 50;
	color_1 = color_1.replace('#','');
	color_2 = color_2.replace('#','');
	let color = "#";
	for(let i = 0; i <= 5; i += 2) {
		let v1 = h2d(color_1.substr(i, 2));
		let v2 = h2d(color_2.substr(i, 2));
		// console.log(v1,v2);
		let val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));
		while(val.length < 2) { val = '0' + val; }
		color += val;
	}
	return color;
};

export const getErrorColor = (colors) => {

	// console.log(colors);

	// colors[0] should be the background
	const bg = colors.shift()

	// are any of the colours already red?
	// (get the most red)

	let reds = {};

	for (const c of colors) {
		if (c) {
			let red = isRed(c);
			if (red && (getContrast(c, bg) > 2)) reds[c] = red;
		}
	}

	if (Object.keys(reds).length) {
		let sorted = Object.entries(reds).sort((a, b) => b[1] - a[1]);
		// console.log(sorted);
		return sorted[0][0];
	}

	// do any of them have a triad or tetrad color that's red?
	for (const c of colors) {
		if (c) {
			let tc = tinycolor(c);
			let triad = tc.triad();
			triad.shift();
			for (const cx of triad) {
				// console.log(cx.toHexString());
				if (isRed(cx) && (getContrast(cx, bg) > 2)) return cx.toHexString();
			}
			let tetrad = tc.tetrad();
			tetrad.shift();
			for (const cy of tetrad) {
				// console.log(cy.toHexString());
				if (isRed(cy) && (getContrast(cy, bg) > 2)) return cy.toHexString();
			}
		}
	}

	// fallback
	return colors[0];

};

const isRed = (color) => {
	let tc = tinycolor(color);
	let hsv = tc.toHsv();
	let hue = hsv.h;
	// console.log(hsv);
	if (hsv.s > 0.2) { // not grey
		if (hsv.h > 320) return (100 - (360 - hsv.h));
		if (hsv.h < 20)  return (100 - hsv.h);
	}
	return 0;
};

// NB: the threshold here is much darker than for the platform
export const isDark = (color) => {
	if (!color) return null;
	return (getBrightness(color) < 50) ? true : false;
};

export const getBrightness = (color) => {
	if (!color) return null;
	let tc = tinycolor(color);
	return tc.getBrightness();
};

export const getContrast = (color1, color2) => {
	return tinycolor.readability(color1, color2);
};
