<script>

	import RadioButton from '../../ui/RadioButton.svelte';

	import { displayLang, event } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let field;

	let name = field.name ? field.name : field.ref;

	// This is unique here
	function isDisabled(o) {
		if (field.disabled) return true;
		if ((o.hook == 'card') && (!$event.psp)) return true;
		return false;
	}

	// Set default
	if (field.options && !field.value) {
		for (const o of field.options) {
			if ((o.hook == 'card') && ($event.psp)) {
				field.value = o.ref;
				break;
			} else if ((o.hook == 'invoice') && (!$event.psp)) {
				field.value = o.ref;
				break;
			}
		}
	}

</script>

{#if field.options}
	<div class="radiobuttons">
		{#each field.options as o}
			<p class:disabled={isDisabled(o)}>
				<RadioButton>
					<input
						type="radio"
						id="azn_i_{name}_{o.ref}"
						name={name}
						bind:group={field.value}
						value={o.ref}
						required="{field.required ? 'required' : ''}"
						on:change
						on:blur
						disabled={isDisabled(o)}
					>
				</RadioButton>
				<label for="azn_i_{name}_{o.ref}">{getTextFromDict(o.name, $displayLang)}</label>
			</p>
		{/each}
	</div>
{/if}
