<script>

	import { onMount } from 'svelte';

	import Select from '../../ui/Select.svelte';

	import { getServerData } from '../../prelude.js';
	import { getAmounts } from '../../amounts.js';

	import { crmFields, displayLang, countryOpts, clientCountry, bookingCountry, currentScreen } from '../../stores.js';
	// import { getTextFromDict } from '../../i18n.js';

	export let field;

	let options = [];

	async function setOptions() {
		if (field.options) {
			options = field.options;
		} else {
			if (field.component == 'Country') {
				options = $countryOpts;
				field.default = $clientCountry;
			} else if (field.crm) {
				for (const c of $crmFields) {
					if (c.ref == field.crm) {
						options = c.options;
						break;
					}
				}
			}
		}
	}

	function setValueFromDefault() {
		if (field.value === undefined) { // allow null to remain intact
			field.value = field.default;
		}
	}

	let mounted = false;

	onMount(async () => {
		if (!$countryOpts.length) {
			const data = await getServerData('/sites/countries', { lang: $displayLang });
			countryOpts.set(data.countries);
			clientCountry.set(data.clientCountry);
		}
		if (field.hook == 'bookingCountry') {
			$bookingCountry = $clientCountry;
			if ($currentScreen == 2) {
				await getAmounts();
			}
		}
		mounted = true;
	});

	$: if (mounted && field) {
		setOptions();
		setValueFromDefault();
	}

</script>

{#if mounted}
	<Select
		id="azn_i_{field.name ? field.name : field.ref}"
		bind:value={field.value}
		options={options}
		on:set
		disabled={field.disabled}
	></Select>
{/if}
