<script>

	import { getAmounts } from '../../amounts.js';
	import { displayLang, displayAmounts, amountsDebounce, taxID } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let field;

	let name = field.name ? field.name : field.ref;

	let inputmode = 'text';

	if (!field.validationCallback) {
		field.validationCallback = async (event) => {

			if (event && event.type == 'input') {
				return true;
			}

			if (field.value) {

				clearTimeout($amountsDebounce);

				$taxID = field.value;

				if (event) {
					$amountsDebounce = setTimeout(async () => {
						let errors = await validateTaxID();
						return errors;
					}, 200);
				} else {
					// Immediately act on submit
					let errors = await validateTaxID();
					return errors;
				}

			}

			// console.log('No Tax ID to validate');

			field.validationStatus = null;

			return true;

		};
	}

	async function validateTaxID() {
					
		await getAmounts();

		if ($displayAmounts.taxID) {

			field.validationStatus = null;

			return true;

		} else {

			// console.log('Tax ID failed validation');

			// TODO: i18n
			field.validationStatus = {
				type: "error",
				message: "Sorry, we could not validate this ID"
			};

			return false;

		}

	}

</script>

<div>

	<input
		type="text"
		id="azn_i_{name}"
		{name}
		bind:value="{field.value}"
		placeholder="{getTextFromDict(field.placeholder, $displayLang)}"
		required="{field.required ? 'required' : ''}"
		{inputmode}
		on:input
		on:blur
		disabled={field.disabled}
	/>

</div>
