<script>

	import { onMount, onDestroy, tick } from 'svelte';
	import { slide, fade } from 'svelte/transition';

	import { uuid } from 'uuidv4';

	import { sleep, asyncForEach } from '../utils.js';
	import { validateField } from '../validation.js';

	import { postServerData } from '../prelude.js';

	import { formToken, displayAmounts, timeoutRelease, screens, currentScreen, busy, boxHeight, bigError, ticketTypes, ticketType, inventory, attendeeToken, simplifiedFlow, allowAutoscroll, invitee } from '../stores.js';

	import FormComponent from './FormComponent.svelte';
	import ValidationErrors from './ValidationErrors.svelte';

	let mounted = false;

	onMount(async () => {

		// console.log('Mounting FormSet', $currentScreen, $screens);

		if (!($screens[$currentScreen].responseSets && $screens[$currentScreen].responseSets.length)) {
			$screens[$currentScreen].responseSets = [];
			addResponseSet();
			cloneHookedResponses(); // only happens for first responseSet
		}

		if (($ticketTypes.length == 1) && ($currentScreen == 0)) {

			// Set default value
			for (const [i,r] of $screens[0].responseSets[0].components.entries()) {
				if (r.component == 'TicketType') {
					$screens[0].responseSets[0].components[i].value = $ticketTypes[0].ref;
				}
			}

			if ($simplifiedFlow) {

				// Skip screen if we can

				let hasCustomFields = false;

				for (const c of $screens[0].components) {
					if ((c.type == 'field') && (c.component != 'TicketType') && (!c.omitFromSimplifiedFlow)) {
						hasCustomFields = true;
						break;
					}
				}

				if (!hasCustomFields) {

					$screens[0].status = 'complete';

					const json = getJSON();

					const syncData = await postServerData('/sites/registrations', {
						formToken: $formToken,
						json: json,
						attendee: $attendeeToken
					});

					if (syncData.formToken) {
						formToken.set(syncData.formToken);
						$allowAutoscroll = false;
						$currentScreen = 1;
						setTimeout(() => {
							$allowAutoscroll = true;
						}, 200);
					}

				}
			}

		}

		mounted = true;
		setBoxHeight();

	});

	function addResponseSet() {
		let newResponseSet = {};
		newResponseSet.ref = uuid();
		const cloned = JSON.parse(JSON.stringify($screens[$currentScreen].components));
		newResponseSet.components = cloned;
		$screens[$currentScreen].responseSets.push(newResponseSet);
		$screens = $screens; // trigger update
	}

	function deleteResponseSet(idx) {
		$screens[$currentScreen].responseSets[idx].deleted = true;
		setTimeout(function(){
			$screens[$currentScreen].responseSets.splice(idx,1);
			$screens = $screens; // trigger update
		}, 200);
	}

	function cloneHookedResponses() {
		console.log('cloneHookedResponses');
		if ($screens[$currentScreen].cloneHookedResponseValues && ($currentScreen > 0)) {
			for (const r of $screens[$currentScreen - 1].responseSets[0].components) {
				if (r.hook) {
					for (const c of $screens[$currentScreen].responseSets[0].components) {
						if (c.hook && (c.hook == r.hook)) {
							c.value = r.value;
						}
					}
				}
			}
		}
	}

	// This may ultimately need to be a library of possible adjunct button functions?
	async function handleAdjunctButton(event) {

		const adjunct = event.detail;

		// console.log(adjunct);

		if (adjunct.func == 'addResponseSet') {
			addResponseSet();
		} else if (adjunct.func == 'deleteResponseSet') {
			deleteResponseSet(adjunct.item);
		}

	}

	async function submit() {

		// console.log('submit');

		$busy = true;

		let scr = $currentScreen;

		await setBoxHeight();

		let errors = await validateForm();

		// console.log('submit -> validateForm', errors);

		let redirect;

		if (errors) {

			$boxHeight = 'auto'; // in case this is super quick

		} else {

			if (!$attendeeToken) {
				// All screens up to and including the current one are complete
				// All screens beyond that are not (even if they have response set data)
				$screens.forEach((s,i) => {
					if (i > $currentScreen) {
						$screens[i].status = undefined;
					} else {
						$screens[i].status = 'complete';
					}
				});
			}

			// sync with server
			const json = getJSON();
			// console.log('FORMSET',json);

			const syncData = await postServerData('/sites/registrations', {
				formToken: $formToken,
				json: json,
				attendee: $attendeeToken
			});

			if (!$bigError) {

				if (syncData.formToken) {
					formToken.set(syncData.formToken);
				}

				if (syncData.displayAmounts) {
					displayAmounts.set(syncData.displayAmounts);
				}

				if (syncData.timeoutRelease) {
					timeoutRelease.set(syncData.timeoutRelease);
				}

				let ttReset = false;

				if (syncData.ticketType) {
					ticketType.set(syncData.ticketType);
					console.log('reset ticketType', $ticketType);
					ttReset = true;
				}

				if (syncData.inventory) {
					inventory.set(syncData.inventory);
				}

				if ($invitee && $invitee.attendeeCount && $ticketType) {
					if (ttReset) {
						if ($ticketType.maxAttendeesPerBooking && (parseInt($invitee.attendeeCount) >= parseInt($ticketType.maxAttendeesPerBooking))) {
							$bigError = 'Invitee-Already-Accepted';
						} else if ($ticketType.maxAttendeesPerBooking) {
							// Reduce the number available on this booking by the number already made
							$ticketType.maxAttendeesPerBooking = parseInt($ticketType.maxAttendeesPerBooking) - parseInt($invitee.attendeeCount);
							console.log('(formset ttReset) maxAttendeesPerBooking is now', $ticketType.maxAttendeesPerBooking);
						}
					}
					$screens[1].cloneHookedResponseValues = false;
				}

				// console.log($formToken);
				// console.log($displayAmounts);

				if (syncData.url) {
					// we're off to the checkout...
					redirect = syncData.url;
				} else if ($attendeeToken) {
					scr = 3; // thanks screen
				} else if ((scr == 1) && $simplifiedFlow) {
					scr = 3; // thanks screen
				} else {
					scr = scr + 1;
				}

			}

		}

		if (redirect) {

			window.location.href = redirect;

		} else {

			$busy = false;

			if (scr != $currentScreen) {
				mounted = false;
				history.pushState({ screen: scr }, null, null);
				$currentScreen = scr;
			}

		}

	}

	function getJSON() {
		return JSON.stringify({ screens: $screens });
	}

	async function setBoxHeight() {
		// await tick;
		await sleep(400); // really??
		let h = document.getElementById('aznr_app_inner').offsetHeight;
		$boxHeight = h + 'px';
		// console.log($boxHeight);
	}

	async function validateForm() {

		let errors = false;

		await asyncForEach($screens[$currentScreen].responseSets, async (rs, rsidx) => {
			await asyncForEach(rs.components, async (component, cidx) => {

				await validateField(component);

				if (component.validationStatus && component.validationStatus.type == 'error') {
					errors = true;
				}

				// console.log({errors});

			});
		});

		$screens = $screens; // trigger DOM update

		return errors;

	}

</script>

<!-- <style>
	:global(.azn_wide) .azn_regform {
		padding-left: var(--lhcw);
	}
	:global(.azn_mid) .azn_regform {
		padding-right: var(--rhcw);
	}
</style> -->

{#if mounted}
	<div class="azn_formset" class:submitted={$busy}>

		<form id="azn_regform" transition:fade|local={{delay:300, duration:300}} class="azn_regform screen-{$currentScreen + 1}" on:submit|preventDefault={submit} novalidate>
			{#each $screens[$currentScreen].responseSets as responseSet, responseSetIndex (responseSet.ref)}
				<div class="azn_responseSet" in:slide|local={{ duration: 400 }}>
					{#each responseSet.components as component, componentIndex (component.ref)}
						<FormComponent bind:component={component} {componentIndex} {responseSet} {responseSetIndex} on:adjunct={handleAdjunctButton}/>
					{/each}
				</div>
			{/each}
		</form>

	</div>
{/if}
