<script>

	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { quadIn } from 'svelte/easing';

	import { drawWithLineCap } from '../../drawWithLineCap.js';
	import { event } from '../../stores.js';

	export let component;

	let mounted = false;

	onMount(() => {
		mounted = true;
	});

</script>

{#if mounted}
	<div in:fade={{ delay: 600, duration: 200 }} class="bigtick {component.presentation}">
		<svg viewBox="0 0 80 80">
			<path in:drawWithLineCap={{ delay: 600, duration: 400 }} d="M61.9,9C55.7,4.6,48.2,2,40,2C19,2,2,19,2,40s17,38,38,38s38-17,38-38c0-4-0.6-7.8-1.7-11.4"/>
			<path in:drawWithLineCap={{ delay: 600, duration: 500, easing: quadIn }} d="M22.4,43.5L33,54.1c0.2,0.2,0.5,0.2,0.8,0c1-1,44.3-44.3,44.3-44.3"/>
		</svg>
	</div>
{/if}