<script>

	import { displayLang } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let field;

	let name = field.name ? field.name : field.ref;

	let inputmode = 'text';
	let autocomplete;

	// Possibly extend this to include address?
	if (field.hook == 'firstname') {
		autocomplete = "given-name";
	} else if (field.hook == 'lastname') {
		autocomplete = "family-name";
	}

</script>

<input
	type="text"
	id="azn_i_{name}"
	{name}
	bind:value="{field.value}"
	placeholder="{getTextFromDict(field.placeholder, $displayLang)}"
	required="{field.required ? 'required' : ''}"
	{inputmode}
	{autocomplete}
	on:input
	on:blur
	disabled={field.disabled}
/>
