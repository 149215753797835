<script>

	import { onMount } from 'svelte';

	import DOMPurify from 'dompurify';

	import { getServerData } from '../../prelude.js';
	import { displayLang, screens, ticketTypes, crmFields } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let component;
	export let rs;

	let content = getTextFromDict(component.attendeeDetails, $displayLang); // will return object, not just text
	let html = (content && typeof content === 'object') ? content.html : content;

	let tmp = document.createElement("div");
	tmp.innerHTML = html;

	[...tmp.querySelectorAll('.mention')].forEach(function(mention) {

		const ref = mention.dataset.id;
		const value = getResponse(ref);

		if (value) {
			let span = document.createElement("span");
			span.textContent = value;
			mention.parentNode.replaceChild(span, mention);
		} else {
			mention.parentNode.removeChild(mention);
		}

	});

	html = tmp.innerHTML;

	function getResponse(ref) {
		for (const c of rs.components) {
			if (c.ref && (c.ref == ref)) {
				if (c.options) {
					return getOptionName(c.options, c.value);
				} else if (c.crm) {
					for (const crm of $crmFields) {
						if (crm.ref == c.crm) {
							if (crm.options) {
								return getOptionName(crm.options, c.value);
							} else {
								return c.value;
							}
						}
					}
				} else {
					return c.value;
				}
			}
		}
		for (const c of $screens[0].responseSets[0].components) {
			if (c.ref && (c.ref == ref)) {
				if (c.component == 'TicketType') {
					return getOptionName($ticketTypes, c.value);
				}
			}
		}
	}

	function getOptionName(options, ref) {
		for (const o of options) {
			if (o.ref == ref) {
				return getTextFromDict(o.name,$displayLang)
			}
		}
	}

</script>

<style>
	div :global(p) {
		margin: 0;
	}
</style>

{#if html}
	<div>
		{@html DOMPurify.sanitize(html)}
	</div>
{/if}