<script>

	import { displayLang } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	import { autoresize } from '../../autoresize.js';

	export let field;

</script>

<textarea
	id="azn_i_{field.name ? field.name : field.ref}"
	name="{field.name ? field.name : field.ref}"
	bind:value="{field.value}"
	placeholder="{getTextFromDict(field.placeholder, $displayLang)}"
	required="{field.required ? 'required' : ''}"
	use:autoresize
	on:input
	on:blur
	disabled={field.disabled}
/>
