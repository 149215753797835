export const getTextFromDict = (dict, lang, blank) => {

	let text = (blank != null) ? blank : '';

	if (dict && typeof dict === 'object') {
		if (dict[lang] != null) {
			text = dict[lang];
		} else if (Object.keys(dict).length == 1) {
			for (k in dict) {
				text = dict[k];
				break;
			}
		} else {
			text = (dict.EN && (dict.EN != null)) ? dict.EN : '';
		}
	} else {
		text = '';
	}

	return text;

}