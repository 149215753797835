<script>

	import { onMount } from 'svelte';
	import { getContext } from 'svelte';

	import isEmail from 'validator/es/lib/isEmail';

	import { displayLang, screens, currentScreen } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let field;

	let name = field.name ? field.name : field.ref;

	let inputmode = 'email';
	let autocomplete = 'email';

	const responseSetRef = getContext('responseSetRef');

	// console.log('responseSetRef', responseSetRef);

	onMount(() => {
		// console.log('Setting email field validationCallback');
		field.validationCallback = checkEmail
	});

	function checkEmail(event) {

		if (event && event.type == 'input') {
			return true;
		}

		if (field.value) {
			if (!isEmail(field.value)) {
				const message = 'Please enter a valid email address.'; // TODO: i18n
				field.validationStatus = {
					type: "error",
					message: message
				};
				return false;
			}
		}

		if (($currentScreen == 1) && (field.hook == 'e')) {
			for (const rs of $screens[$currentScreen].responseSets) {
				if (rs.ref != responseSetRef) {
					for (const c of rs.components) {
						if (c.hook == 'e') {
							// console.log('email check', $currentScreen, responseSetRef, c.value, field.value);
							if (c.value == field.value) {
								const message = 'Please enter a unique email address'; // TODO: i18n
								field.validationStatus = {
									type: "error",
									message: message
								};
								return false;
							}
							break;
						}
					}
				}
			}
		}

		return true;

	}

</script>

<input
	type="text"
	id="azn_i_{name}"
	{name}
	bind:value="{field.value}"
	placeholder="{getTextFromDict(field.placeholder, $displayLang)}"
	required="{field.required ? 'required' : ''}"
	{inputmode}
	{autocomplete}
	on:input
	on:blur
	disabled={field.disabled}
/>
