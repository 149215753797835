<script>

	import { onMount } from 'svelte';

	import DOMPurify from 'dompurify';

	import dayjs from "dayjs";
	import localizedFormat from "dayjs/plugin/localizedFormat";
   dayjs.extend(localizedFormat);

	import { getServerData } from '../../prelude.js';
	import { displayLang, screens, ticketTypes, crmFields, displayAmounts, paymentAmount, event, sitemap } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let component;

	let content = getTextFromDict(component.content,$displayLang); // will return object, not just text
	let html = (content && typeof content === 'object') ? content.html : content;

	let tmp = document.createElement("div");
	tmp.innerHTML = html;

	[...tmp.querySelectorAll('.mention')].forEach(function(mention) {

		const ref = mention.dataset.id;
		const value = ref.startsWith("__") ? getAmount(ref) : getResponse(ref);

		if (value) {
			let span = document.createElement("span");
			span.textContent = value;
			mention.parentNode.replaceChild(span, mention);
		} else {
			mention.parentNode.removeChild(mention);
		}

	});

	html = tmp.innerHTML;

	function getAmount(key) {
		key = key.substring(2);
		switch (key) {
			case "attendeeCount":
				return $displayAmounts[key];
			case "vatRate":
				return $displayAmounts[key] + '%';
			case "discountPercentage":
				return $displayAmounts[key] ? $displayAmounts[key] + '%' : '0%';
			default:
				return Number($displayAmounts[key]).toLocaleString($event.locale, {style:'currency', currency:$event.currency});
		}
	}

	function getResponse(ref) {
		for (const s of $screens) {
			if (s.responseSets && s.responseSets.length) {
				for (const c of s.responseSets[0].components) {
					if (c.ref && (c.ref == ref)) {
						if (c.component == 'TicketType') {
							return getOptionName($ticketTypes, c.value);
						} else if (c.component == 'DateField') {
							return dayjs(c.value).format('ll');
						} else if (c.options) {
							return getOptionName(c.options, c.value);
						} else if (c.crm) {
							for (const crm of $crmFields) {
								if (crm.ref == c.crm) {
									if (crm.options) {
										return getOptionName(crm.options, c.value);
									} else {
										return c.value;
									}
								}
							}
						} else {
							return c.value;
						}
					}
				}
			}
		}
	}

	function getOptionName(options, ref) {
		for (const o of options) {
			if (o.ref == ref) {
				return getTextFromDict(o.name,$displayLang)
			}
		}
	}

	let mounted = false;

	onMount(() => {

		if (html) {
			const regex = new RegExp(`"azn://page/(.*?)"`, 'g');
			html = html.replace(regex, function (string, capture) {
				for (const p of $sitemap) {
					// console.log(p.ref, capture);
					if (p.ref == capture) {
						return $event.url + p.path;
					}
				}
				return string;
			});
		}

		mounted = true;

	});

	let appElement = document.getElementById('aznr');
	let bgColor = getComputedStyle(appElement).getPropertyValue('--bgColor');

	let shaded = ($event.colors[component.bgColor] != bgColor) ? true : false;

</script>

<!-- <style>
	:global(.azn_mid .TextBlock) {
		margin-right: calc(0px - var(--rhcw));
	}
	:global(#aznr) .textcontent :global(h2) {
		font-size: 120%;
	}
	:global(.azn_wide .screen-4 .TextBlock) {
		margin-left: calc(0px - var(--lhcw));
	}
</style> -->

{#if mounted && html}
	<div class="textcontent" class:shaded class:indented={component.indent} style="--alignment:{component.alignment};">
		{@html DOMPurify.sanitize(html)}
	</div>
{/if}