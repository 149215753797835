<script>

	// TODO: i18n

	import { createEventDispatcher, getContext } from 'svelte';
	import { slide, fade } from 'svelte/transition';

	import { displayLang, screens, currentScreen, ticketTypes, formToken, bigError, ticketType, inventory, attendee, event, invitee, preapprovalStatus, waitlistStatus, simplifiedFlow, displayAmounts } from '../../stores.js';
	// import { getTextFromDict } from '../../i18n.js';
	import { postServerData } from '../../prelude.js';

	const dispatch = createEventDispatcher();
	const checkinAttendee = getContext('checkinAttendee');

	export let component;
	export let responseSetIndex;

	let _debug = false;

	let isRepeater = false;
	let isSolo = false;
	let isLast = false;

	let canAddAttendee = false;
	let canDeleteAttendee = false;

	let buttonText = 'Next';

	$: if ($screens) {
		setConditions();
		checkButtonText();
	}

	function setConditions() {

		if ($screens[$currentScreen].repeater) {

			isRepeater = true;
			canDeleteAttendee = true;

			// 24 May 2024 -- Note: 
			// for invitees, we've already reduced maxAttendeesPerBooking
			// by the number of attendees already booked (if any)

			canAddAttendee = (!$simplifiedFlow && $inventory && ($inventory.available > 0) && !($ticketType.maxAttendeesPerBooking && ($screens[$currentScreen].responseSets.length >= parseInt($ticketType.maxAttendeesPerBooking))) && !checkinAttendee) ? true : false;

			// console.log('add attendee',$inventory,canAddAttendee);

			if ($preapprovalStatus || $waitlistStatus) {
				canAddAttendee = false;
				canDeleteAttendee = false;
			}

		}

		if ($screens[$currentScreen].responseSets.length == 1) {
			isSolo = true;
		} else {
			isSolo = false;
		}

		if (responseSetIndex == ($screens[$currentScreen].responseSets.length - 1)) {
			isLast = true;
		} else {
			isLast = false;
		}

	}

	function checkButtonText() {
		if (($currentScreen == 1) && $attendee) {

			buttonText = 'Save';

		} else if (($currentScreen == 1) && $simplifiedFlow) {

			buttonText = 'Register now';

		} else if ($currentScreen == 2) {

			// if (!$ticketType.price || !$event.psp) {
			if (!$displayAmounts.grossAmount || !$event.psp) {
				buttonText = 'Confirm booking';
			} else if (($ticketType.paymentOptions.length == 1) && ($ticketType.paymentOptions[0] == 'card')) {
				buttonText = 'Pay now';
			} else if (($ticketType.paymentOptions.length == 1) && ($ticketType.paymentOptions[0] == 'invoice')) {
				buttonText = 'Confirm booking';
			} else {
				for (const c of $screens[$currentScreen].responseSets[0].components) {
					if (c.component == 'PaymentOptions') {
						let v = c.value;
						if (v) {
							for (const o of c.options) {
								if (o.ref == v) {
									if (o.hook == 'card') {
										buttonText = 'Pay now';
									} else {
										buttonText = 'Confirm booking';
									}
									break;
								}
							}
						} else {
							buttonText = 'Next';
						}
						break;
					}
				}
			}

		} else {

			buttonText = 'Next';

		}
	}

	// function triggerAdjunct(func) {
	// 	dispatch('adjunct', { func: func, item: responseSetIndex });
	// }

	async function addAttendee() {

		let quantity = $screens[$currentScreen].responseSets.length + 1;

		// console.log('addAttendee', quantity);

		const inventoryData = await postServerData('/sites/registrations/inventory', {
			formToken: $formToken,
			quantity: quantity,
			ticketType: $ticketType.ref
		});

		if (!$bigError) {
			if (inventoryData.inventory) {
				$inventory = inventoryData.inventory;
			}
			if (inventoryData.allocated == quantity) {
				dispatch('adjunct', { func: 'addResponseSet', item: responseSetIndex });
			} else {
				canAddAttendee = false;
			}
		}

	}

	async function deleteAttendee() {

		let quantity = $screens[$currentScreen].responseSets.length - 1;

		const inventoryData = await postServerData('/sites/registrations/inventory', {
			formToken: $formToken,
			quantity: quantity,
			ticketType: $ticketType.ref
		});

		if (!$bigError) {
			if (inventoryData.inventory) {
				$inventory = inventoryData.inventory;
			}
			if (inventoryData.allocated == quantity) {
				dispatch('adjunct', { func: 'deleteResponseSet', item: responseSetIndex });
			} else {
				canDeleteAttendee = false; // how??!
			}
		}

	}

</script>

<!-- <style>
	:global(#aznr .azn_component + .SubmitButton) {
		margin-top: 1rem;
	} 
	:global(.azn_mid .SubmitButton) {
		margin-right: calc(0px - var(--rhcw));
	}
	:global(.azn_wide .SubmitButton) {
		margin-left: calc(0px - var(--lhcw));
	}
	:global(.azn_wide .SubmitButton) .azn_block {
		border-top: 2px solid var(--textColor);
	}
	:global(.azn_wide .SubmitButton) .azn_block.delete {
		border-top: 1px solid var(--textColor);
	}
</style> -->

{#if !$attendee && isRepeater && !isSolo && canDeleteAttendee && $ticketType && (!$invitee || (responseSetIndex > 0))}
	<div class="azn_block delete" id="azn_f_{component.ref}_delete" out:fade|local>
		<div>
			<button type="button" class="adjunct button" on:click|preventDefault={deleteAttendee}>
				<span class="azn_icon"><svg viewBox="0 0 31 31"><path d="M15.5 20l-11 11-4.5-4.5 11-11-11-11 4.5-4.5 11 11 11-11 4.5 4.5-11 11 11 11-4.5 4.5-11-11z"/></svg></span>
				{#if $invitee}
					<span>Delete this guest</span>
				{:else}
					<span>Delete this attendee</span>
				{/if}
			</button>
		</div>
	</div>
{/if}

{#if isLast}
	<div class="azn_block" id="azn_f_{component.ref}">

		{#if !$attendee && isRepeater && canAddAttendee && $ticketType}
			<div class="adjuncts" out:fade|local>
				<button type="button" class="adjunct button" on:click|preventDefault={addAttendee}>
					<span class="azn_icon"><svg viewBox="0 0 37.5 37.5"><path d="M21.9 21.9v15.5h-6.4v-15.5h-15.5v-6.4h15.5v-15.5h6.4v15.5h15.6v6.4h-15.6z"/></svg></span>
					<!-- {#if $invitee && (responseSetIndex > 0)}
						<span>Add another guest</span>
					{:else if $invitee}
						<span>Add a guest</span>
					{:else}
						<span>Add another attendee</span>
					{/if} -->
					<span>Add another attendee</span>
				</button>
			</div>
		{/if}

		<div class="azn_next">
			<button type="submit" class="button">{buttonText}</button>
		</div>

	</div>
{/if}

{#if _debug}
	<pre>{JSON.stringify($screens[$currentScreen].responseSets, null, 2)}</pre>
{/if}