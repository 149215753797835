<script>

	import { createEventDispatcher } from 'svelte';
	import { fly } from 'svelte/transition';

	import { displayLang } from '../stores.js';
	import { getTextFromDict } from '../i18n.js';

	const dispatch = createEventDispatcher();

	export let id;
	export let value = null;
	export let options;

	// console.log(value);

	export let nullOption = '';
	export let showNull = true;

	export let subtle = false;
	export let disabled = false;

	let displayOptions;
	$: if (options) displayOptions = getNames();

	function getNames() {
		let opts = [];
		for (const o of options) {
			opts.push({
				groupname: o.groupname,
				value: o.value ? o.value : o.ref,
				label: o.name ? getTextFromDict(o.name,$displayLang,'—') : getTextFromDict(o.label,$displayLang,'—')
			});
		}
		return opts;
	}

	let open = false;

	function openOptions() {
		if (!disabled) {
			open = true;
		}
	}

	function toggleOptions() {
		if (!disabled) {
			open = !open;
		}
	}

	function closeOptions() {
		open = false;
	}

	let filterText = '';
	let filtered;

	$: if (displayOptions || !open) filterText = '';
	$: filtered = filterOptions(filterText);

	function filterOptions(txt) {
		let opts = [];
		for (const o of displayOptions) {
			if ((txt == '') || (o.label.toLowerCase().includes(txt.toLowerCase()))) {
				opts.push(o);
			}
		}
		return opts.length ? opts : displayOptions;
	}

	let placeholder;
	$: placeholder = getNameFromValue(value);

	function getNameFromValue(v) {
		// console.log('getNameFromValue', v);
		if (!v) return nullOption;
		for (const o of displayOptions) {
			if (o.value == v) {
				return o.label;
			}
		}
		return nullOption;
	}

	let highlighted = -1;
	let hovering = false;

	$: if (!open) {
		highlighted = -1;
		hovering = false;
	}

	function handleOptionClick(e) {
		setValue(e.target.dataset.value);
	}

	function setValue(v) {
		if (v === undefined) v = null;
		value = v;
		open = false;
		highlighted = -1;
		dispatch('set');
		// console.log('setValue',value);
	}

	let newFilter = true;
	let newFilterTimeout;

	function handleKeyDown(e) {
		switch (e.key) {
			case "ArrowDown":
				e.preventDefault();
				openOptions();
				if (!hovering && (highlighted != filtered.length)) {
					highlighted++;
					if ((highlighted == 0) && (!showNull)) {
						highlighted = 1;
					}
					setSheetScroll();
				}
				break;
			case "ArrowUp":
				e.preventDefault();
				openOptions();
				if (!hovering) {
					if (highlighted == -1) {
						highlighted = filtered.length;
					} else if (highlighted != (showNull ? 0 : 1)) {
						highlighted--;
					}
					setSheetScroll();
				}
				break;
			case "Enter":
				e.preventDefault();
				if (highlighted == -1) {
					openOptions();
				} else if (highlighted == 0) {
					setValue(undefined);
				} else if (filtered[highlighted - 1]) {
					setValue(filtered[highlighted - 1].value);
				}
				break;
			case "Escape":
				e.preventDefault();
				if (filtered.length != displayOptions.length) {
					filterText = '';
				} else {
					closeOptions();
					highlighted = -1;
				}
				break;
			default:
				if ((e.which >= 48) && (e.which <= 90)) {
					e.preventDefault();
					openOptions();
					clearTimeout(newFilterTimeout);
					highlighted = -1;
					if (newFilter) {
						filterText = String.fromCharCode(e.which);
						newFilter = false;
					} else {
						filterText = filterText + String.fromCharCode(e.which);
					}
					newFilterTimeout = setTimeout(function(){
						newFilter = true;
					}, 800);
					break;
				}
		}
	}

	let container;
	let sheet;
	let sheetOverflow = false;

	$: if (sheet) {
		sheetOverflow = opensInOverflow();
	}

	function setSheetScroll() {
		const lit = document.getElementById(id + '_opt_' + highlighted);
		if (lit) {
			const scroll = sheet.scrollTop;
			const sheetHeight = sheet.offsetHeight;
			const litTop = lit.offsetTop;
			const litHeight = lit.offsetHeight;
			// console.log(litTop, scroll, (sheetHeight - scroll));
			if ((litTop + litHeight) > (sheetHeight - scroll)) {
				const newScroll = litTop;
				// console.log(newScroll);
				sheet.scrollTop = newScroll;
			} else if (litTop < scroll) {
				const newScroll = litTop;
				// console.log(newScroll);
				sheet.scrollTop = newScroll;
			}
		}
	}

	function getPanel(el) {
		for (; el; el = el.offsetParent) {
			if (el.classList.contains('aznr_app')) {
				return el;
			}
		}
	}

	function abstop(el, boundary) {
		let y = 0;
		for (; el; el = el.offsetParent) {
			y += el.offsetTop;
			if (boundary && (el == boundary)) break;
		}
		return y;
	}

	function opensInOverflow() {
		const panel = getPanel(container);
		const scroll = panel ? panel.scrollTop : 0;
		const top = abstop(container, panel) - scroll;
		const panelHeight = panel ? panel.offsetHeight : 0;
		const containerHeight = container.offsetHeight;
		const sheetHeight = sheet.offsetHeight;
		const absBottom = (top + containerHeight + sheetHeight);
		const viewportOffset = container.getBoundingClientRect();
		const viewportBottom = viewportOffset.bottom + sheetHeight;
		const out = (absBottom > panelHeight) || (viewportBottom > (window.innerHeight || document.documentElement.clientHeight));
		// console.log(scroll, top, panelHeight, containerHeight, sheetHeight, absBottom, viewportBottom, window.innerHeight, out);
		return out;
	}

	$: if (disabled) {
		closeOptions();
	}

</script>

<div class="Select" class:subtle bind:this={container}>
	<button
		type="button"
		id={id}
		class:open
		class:null={placeholder == nullOption}
		on:click={toggleOptions}
		on:blur={closeOptions}
		on:keydown|stopPropagation={handleKeyDown}
		role="listbox"
		aria-haspopup="true"
		aria-expanded={open}
		aria-owns={open ? id + '_options' : undefined}
		aria-activedescendant={(highlighted == -1) ? undefined : id + '_opt_' + highlighted}
	>
		<span>{ placeholder }</span>
		<svg viewBox="0 0 100 100"><path d="M47.42 3.23l-19.27 33.38c-1.05 1.83.26 4.11 2.37 4.11h38.54c2.11 0 3.43-2.28 2.37-4.11l-19.27-33.38c-1.05-1.82-3.69-1.82-4.74 0zM47.84 96.77l-19.27-33.38c-1.05-1.83.26-4.11 2.37-4.11h38.54c2.11 0 3.43 2.28 2.37 4.11l-19.27 33.38c-1.05 1.82-3.69 1.82-4.74 0z"/></svg>
	</button>
	{#if open}
		<div
			id="{id}_options"
			class="options"
			bind:this={sheet}
			class:low={sheetOverflow}
			transition:fly|local={{ duration: 200, y: -3 }}
			on:mouseover={() => {hovering = true}}
			on:mouseout={() => {hovering = false}}
		>
			<ul>
				{#if showNull}
					<li
						id="{id}_opt_0"
						data-value={null}
						class="null"
						class:highlighted={highlighted == 0}
						role="option"
						on:click={handleOptionClick}
						on:mouseover={() => {highlighted = 0}}
					>
						{nullOption}
					</li>
				{/if}
				{#each filtered as o, i (o.value)}
					{#if (o.groupname && ((i == 0) || (o.groupname != filtered[i-1].groupname)))}
						<li class="groupname">{o.groupname}</li>
					{/if}
					<li
						id="{id}_opt_{i+1}"
						data-value={o.value}
						class:highlighted={highlighted == i + 1}
						class:hasGroupname={o.groupname}
						role="option"
						on:click={handleOptionClick}
						on:mouseover={() => {highlighted = i + 1}}
					>
						{o.label}
					</li>
				{/each}
			</ul>
		</div>
	{/if}
</div>