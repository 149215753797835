<script>

	import { onMount, onDestroy, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';

	import { bigError, ticketTypes, event, screens, displayLang, ticketType } from '../stores.js';
	import { getTextFromDict } from '../i18n.js';
	import { goHome } from '../utils.js';

	const dispatch = createEventDispatcher();

	function getAmountString(amount) {
		let n = Number(amount);
		if (n) {
			return n.toLocaleString($event.locale, {style:'currency', currency:$event.currency});
		} else {
			return 'free';
		}
	}

	let originalBodyOverflow = document.body.style.overflow;

	onMount(() => {
		document.body.style.overflow = 'hidden';
	});

	onDestroy(() => {
		document.body.style.overflow = originalBodyOverflow;
	});

</script>

<div class="BigError" in:fade={{ duration:200, delay:100 }}>
	<div>

		{#if $bigError == 'Staging'}

			<p><strong>You’re viewing this in staging.</strong></p>
 			<p>Any actions you take here may be stored, but will not apply to the event.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={() => { $bigError = null }}>OK</button>
			</p>

		{:else if $bigError == 'Event status'}

			<p><strong>Sorry, registration for this event is now closed.</strong></p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if $bigError == 'Edits unavailable'}

			<p><strong>Sorry, it is not possible to edit this booking.</strong></p>
 			<p>Please contact the event organisers for assistance.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if $bigError == 'Edits expired'}

			<p><strong>Sorry, it is no longer possible to edit this booking.</strong></p>
 			<p>Please contact the event organisers for assistance.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if $bigError == 'Inventory'}

			<p><strong>Sorry, this ticket type is now unavailable.</strong></p>
			<p class="buttons">
				<button type="button" class="button" on:click={() => { dispatch('restart') }}>Try again</button>
			</p>

		{:else if $bigError == 'Tickets'}

			<p><strong>Sorry, no tickets are currently available.</strong></p>
			<p class="buttons">
				<button type="button" class="button" on:click={() => { dispatch('restart') }}>Check again</button>
			</p>

		{:else if $bigError == 'Timeout'}

			<p><strong>Your session has timed out.</strong></p>
			<p class="buttons">
				<button type="button" class="button" on:click={() => { dispatch('extend') }}>Check availability</button>
			</p>

		{:else if $bigError == 'Price'}

			<p><strong>The price of this ticket has changed.</strong></p>
			{#if ticketType}
				<p>{getTextFromDict($ticketType.name, $displayLang)} tickets are now {getAmountString($ticketType.price)}.</p>
			{/if}
			<p class="buttons">
				<button type="button" class="button" on:click={() => { dispatch('reallocate') }}>Continue</button>
				<button type="button" class="button" on:click={() => { dispatch('restart') }}>Cancel</button>
			</p>

		{:else if $bigError == 'Attendees'}

			<p>
				<strong>
					{#if $screens[1].responseSets.length > 1}
						It looks like there’s already an attendee with an email address you’ve entered here.
					{:else}
						It looks like there’s already an attendee with this email address.
					{/if}
				</strong>
			</p>
			<p>Each individual attendee requires their own email address to register.</p>
			<p>Please use a different address.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={() => { dispatch('fixAttendees') }}>Edit attendees</button>
			</p>

		{:else if $bigError == 'Invitee-Already-Accepted'}

			<p><strong>Sorry, this invitation has already been accepted.</strong></p>
 			<p>Please contact the event organisers for assistance.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if $bigError == 'Invitee-Already-Declined'}

			<p><strong>Sorry, this invitation has already been declined.</strong></p>
 			<p>Please contact the event organisers for assistance.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if $bigError == 'Preapproval'}

			<p><strong>Thank you for requesting a place at this event.</strong></p>
 			<p>Our team are reviewing your registration and we’ll get back to you soon.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if $bigError == 'Waitlist'}

			<p><strong>Thank you for requesting a place at this event.</strong></p>
 			<p>Your details have been added to our waitlist and if a place becomes available, we’ll be in touch.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if $bigError == 'Will waitlist'}

			<p><strong>Sorry! No places are currently available.</strong></p>
 			<p>If you wish, you can add your details to the waitlist and we’ll let you know if a place becomes available.</p>
 			<p>To join the waitlist, continue filling out the form.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={() => { $bigError = null }}>OK</button>
			</p>

		{:else if $bigError == 'Preapproval expired'}

			<p><strong>Sorry, it is no longer possible to complete this booking.</strong></p>
 			<p>Please contact the event organisers for assistance.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if $bigError == 'Waitlist expired'}

			<p><strong>Sorry, it is no longer possible to complete this booking.</strong></p>
 			<p>Please contact the event organisers for assistance.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if $bigError == 'Checkout'}

			<p><strong>Sorry, there has been a problem setting up payment.</strong></p>
 			<p>Please ensure your billing address is accurate and complete, then try again.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={() => { $bigError = null }}>OK</button>
			</p>

		{:else}

			<p><strong>{$bigError}</strong></p>

		{/if}

	</div>
</div>
