<script>

	// TODO: i18n on table headings
	// TODO: acknowledge amountPaid ..?

	import { slide, fade } from 'svelte/transition';

	import { screens, displayAmounts, event } from '../../stores.js';

	import AttendeeDetails from './AttendeeDetails.svelte';

	export let component;

	function getAmountString(amount, currency) {
		let n = Number(amount);
		if (n) {
			return n.toLocaleString($event.locale, {style:'currency', currency:currency});
		} else {
			return '';
		}
	}

	let showTotals = (component.presentation == 'none') ? false : true;

	let taxAmount = getAmountString($displayAmounts.vatAmount, $displayAmounts.currency);

	// console.log('RegistrationSummary', $displayAmounts);

</script>

<div class="azn_block textcontent" id="azn_f_{component.ref}">
	<table class="attendees" class:nocosts={!showTotals || (!$displayAmounts.discountPercentage && !$displayAmounts.netAmount)}>
		{#each $screens[1].responseSets as rs (rs.ref)}
			<tr>
				<td class="attendee">
					<AttendeeDetails {component} {rs}/>
				</td>
				{#if $displayAmounts.attendees && $displayAmounts.attendees[rs.ref]}
					<td class="attendee cost">{getAmountString($displayAmounts.attendees[rs.ref].discountedTicketPrice, $displayAmounts.currency)}</td>
				{:else}
					<td class="attendee cost">{getAmountString($displayAmounts.discountedTicketPrice, $displayAmounts.currency)}</td>
				{/if}
			</tr>
		{/each}
	</table>
	{#if showTotals}
		<!-- {#if $displayAmounts.discountPercentage}
			<div transition:slide|local>
				<table class="totals">
					<tr>
						<td class="total label">Sub total</td>
						<td class="total cost">{getAmountString($displayAmounts.subTotal, $displayAmounts.currency)}</td>
					</tr>
					<tr>
						<td class="total label">Discount</td>
						<td class="total cost">&minus; {getAmountString($displayAmounts.discountAmount, $displayAmounts.currency)}</td>
					</tr>
				</table>
			</div>
		{/if} -->
		{#if ($displayAmounts.netAmount && (Number($displayAmounts.netAmount > 0)))}
			<div transition:slide|local>
				<table class="totals">
					<tr>
						<td class="total label">Net amount</td>
						<td class="total cost">{getAmountString($displayAmounts.netAmount, $displayAmounts.currency)}</td>
					</tr>
					{#if taxAmount}
						<tr>
							<td class="total label">Tax<!--  @ {$displayAmounts.vatRate}% --></td>
							<td class="total cost">{taxAmount}</td>
						</tr>
					{/if}
					<tr>
						<td class="grand total label">Total to pay</td>
						<td class="grand total cost"><strong>{getAmountString($displayAmounts.grossAmount, $displayAmounts.currency)}</strong></td>
					</tr>
				</table>
			</div>
		{/if}
	{/if}
</div>