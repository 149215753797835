<script>

	import { onMount, onDestroy, tick } from 'svelte';

	import { displayLang } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	import Datepicker from 'svelte-calendar';

	export let field;

	let start = new Date(1900, 1, 1);
	let end = new Date(2099, 12, 31);

	let dateFormat = '#{j} #{F} #{Y}';

	let chosen = false;
	let selectedDate = field.value;
	let formattedDate;
	let displayDate;

	let picker;
	let pickerButton;
	let isOpen = false;
	let ch;

	$: if (chosen) {
		setDate();
	}

	async function setDate() {
		field.value = selectedDate;
		await tick();
		displayDate = formattedDate;
		chosen = false;
	}

	let name = field.name ? field.name : field.ref;

	async function handleFocus() {
		await tick();
		triggerClick();
	}

	async function triggerClick() {
		setTimeout(() => {
			pickerButton.closest('.trigger').click();
		}, 150); // just enough to clear a click registering on the input after the one above for the trigger
	}

	onMount(() => {
		document.addEventListener('keydown', handleKeyPress);
	});

	onDestroy(() => {
		document.removeEventListener('keydown', handleKeyPress);
	});

	function handleKeyPress(e) {
		if (isOpen) {
			e.preventDefault();
			e.stopPropagation();
			switch (e.key) {
				case "Tab":
					document.dispatchEvent(new KeyboardEvent('keydown',{'keyCode':27})); // simulate ESC
					let nextField = picker.closest('.DateField').nextElementSibling.querySelector('input');
					if (nextField) nextField.focus();
					break;
			}
		}
	}

</script>

<!-- <style>
	:global(#aznr)	.picker :global(.heading-section .control) {
		transform: none;
		padding: 0.6rem 18px;
		transition: transform 0.2s ease;
	}
	:global(#aznr)	.picker :global(.heading-section .control.enabled:hover, .heading-section .control.enabled:hover) {
		transform: translateX(4px);
	}
	:global(#aznr)	.picker :global(.heading-section .control.enabled:first-child:hover, .heading-section .control.enabled:first-child:hover) {
		transform: translateX(-4px);
	}
	:global(#aznr)	.picker :global(.legend) {
		color: var(--textColor);
		font-size: 0.75rem;
	}
	:global(#aznr)	.picker :global(.legend span) {
		position: relative;
	}
	:global(#aznr)	.picker :global(.legend span:after) {
		content: '';
		position: absolute;
		bottom: -0.7rem;
		left: calc(10% + 2px);
		width: calc(80% - 4px);
		height: 1px;
		background: var(--boxBorderFocusColor);
	}
	:global(#aznr)	.picker :global(.contents) {
		border-radius: 4px;
	}
	:global(#aznr)	.picker :global(.title) {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
	:global(#aznr)	.picker :global(.month-container button.day--label) {
		border-radius: 2px;
		font-size: 0.75rem;
	}
</style> -->

<div class="pick" bind:this={picker}>
	<input
		type="text"
		id="azn_i_{name}"
		name="{name}"
		bind:value="{displayDate}"
		placeholder="{getTextFromDict(field.placeholder, $displayLang)}"
		required="{field.required ? 'required' : ''}"
		on:input
		on:blur
		on:focus={handleFocus}
		readonly={true}
		disabled={field.disabled}
	/>

	{#if field.disabled}
		<div class="picker" bind:clientHeight={ch}>
			<button type="button" class="pickerButton" bind:this={pickerButton} on:click|preventDefault style="height:{ch}px">
				<svg viewBox="0 0 80 80"><path d="M61 20.7h-9v-3.84c0-1.1-.9-2-2-2s-2 .9-2 2v3.84h-16v-3.84c0-1.1-.9-2-2-2s-2 .9-2 2v3.84h-9c-2.21 0-4 1.79-4 4v36.29c0 2.21 1.79 4 4 4h42c2.21 0 4-1.79 4-4v-36.29c0-2.21-1.79-4-4-4zm0 38.3c0 1.1-.9 2-2 2h-38c-1.1 0-2-.9-2-2v-32.31c0-1.1.9-2 2-2h7.01v3.83c0 1.1.9 2 2 2s2-.9 2-2v-3.83h16v3.83c0 1.1.9 2 2 2s2-.9 2-2v-3.83h7c1.1 0 2 .9 2 2v32.31zM49 49h8v8h-8z"></path></svg>
			</button>
		</div>
	{:else}
		<div class="picker" bind:clientHeight={ch}>
			<Datepicker
				format={dateFormat}
				start={start}
				end={end}
				bind:dateChosen={chosen}
				bind:selected="{selectedDate}"
				bind:formattedSelected="{formattedDate}"
				dayTextColor="var(--textColor)"
				highlightColor="var(--linkColor)"
				dayHighlightedBackgroundColor="var(--highlightColor)"
				dayHighlightedTextColor="var(--linkColor)"
			>
				<button type="button" class="pickerButton" bind:this={pickerButton} on:click|preventDefault style="height:{ch}px">
					<svg viewBox="0 0 80 80"><path d="M61 20.7h-9v-3.84c0-1.1-.9-2-2-2s-2 .9-2 2v3.84h-16v-3.84c0-1.1-.9-2-2-2s-2 .9-2 2v3.84h-9c-2.21 0-4 1.79-4 4v36.29c0 2.21 1.79 4 4 4h42c2.21 0 4-1.79 4-4v-36.29c0-2.21-1.79-4-4-4zm0 38.3c0 1.1-.9 2-2 2h-38c-1.1 0-2-.9-2-2v-32.31c0-1.1.9-2 2-2h7.01v3.83c0 1.1.9 2 2 2s2-.9 2-2v-3.83h16v3.83c0 1.1.9 2 2 2s2-.9 2-2v-3.83h7c1.1 0 2 .9 2 2v32.31zM49 49h8v8h-8z"></path></svg>
				</button>
			</Datepicker>
		</div>
	{/if}
</div>
