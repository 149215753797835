<script>

	import { event, displayLang } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let component;

	let label = getTextFromDict(component.label, $displayLang);

</script>

<div class="azn_divider {component.presentation}"></div>
{#if (component.presentation == 'heavy') && label}
	<p class="label"><strong>{label}</strong></p>
{/if}