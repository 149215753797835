<script>

	import { onMount } from 'svelte';
	import { fade,scale } from 'svelte/transition';

	import Spinner from '../../ui/Spinner.svelte';

	import { event, bigError } from '../../stores.js';
	import { getServerData, postServerData } from '../../prelude.js';

	export let field;

	let name = field.name ? field.name : field.ref;

	let mounted = false;
	let uploading = false;

	let filename;

	onMount(async () => {

		if (field.value) {

			const uploadData = await getServerData('/upload/image', {
				ref: field.value
			});

			if (uploadData.image) {
				filename = uploadData.image.filename;
			}

		}

		if (!$bigError) mounted = true;

	});

	async function handleUpload(event) {

		field.validationStatus = null;

		if (event.target && event.target.files && event.target.files.length) {

			const file = event.target.files[0];

			let ok = true;

			// console.log(file.type + ' / ' + file.size);

			if (file.size && file.size > 20971520) { // 20MB in bytes
				field.validationStatus = {
					type: "error",
					message: "This file is too large. Please try another." // TODO: i18n
				};
				ok = false;
			}

			if (ok && file.type && !((file.type == 'image/jpeg') || (file.type == 'image/png'))) { // jpeg or png
				field.validationStatus = {
					type: "error",
					message: "Please upload only a JPEG or PNG image." // TODO: i18n
				};
				ok = false;
			}

			if (ok) {
				uploading = true;
				const syncData = await postServerData('/upload/image', {
					file: file,
					hide: 'true',
					fast: 'true'
				});
				uploading = false;
				if (syncData.image && syncData.image.filename) {
					filename = syncData.image.filename
					field.value = syncData.image.ref;
				} else if (syncData.error) {
					field.validationStatus = {
						type: "error",
						message: syncData.error
					};
				}
			}

		}

	}

	function removeUpload() {
		field.value = null;
	}

</script>

{#if mounted}

	<div class="fileUpload" class:disabled={field.disabled}>
		<span class="placeholder">
			{#if uploading}
				<Spinner size="30" speed="1200" thickness="1" gap="30"/>
			{:else if field.value}
				<img transition:scale|local src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{filename}" alt=""/>
			{:else}
				<svg viewBox="0 0 28.64 32.08"><path d="M14.32 4c3.49 0 6.33 2.83 6.33 6.33s-2.83 6.33-6.33 6.33-6.33-2.83-6.33-6.33 2.84-6.33 6.33-6.33m0-4c-5.69 0-10.33 4.63-10.33 10.33s4.64 10.33 10.33 10.33 10.33-4.63 10.33-10.33-4.63-10.33-10.33-10.33zM9.98 28.66h8.69c2.88 0 4.77 1.89 5.64 3.42h4.33c-.82-2.87-4.25-7.42-9.97-7.42h-8.69c-5.72 0-9.16 4.55-9.98 7.42h4.34c.87-1.52 2.75-3.42 5.64-3.42z"/></svg>
			{/if}
		</span>

		{#if !field.disabled}
			{#if field.value}

				<span class="uploader">
					<button type="button" class="remover" transition:fade|local={{duration:200}} on:click|preventDefault|stopPropagation={removeUpload}>
						<svg viewBox="0 0 100 100"><path d="M50 0c-27.57 0-50 22.43-50 50s22.43 50 50 50 50-22.43 50-50-22.43-50-50-50zm24.03 69.31l-4.72 4.72-19.31-19.31-19.31 19.31-4.72-4.72 19.31-19.31-19.31-19.31 4.72-4.72 19.31 19.31 19.31-19.31 4.72 4.72-19.31 19.31 19.31 19.31z"/></svg>
					</button>
				</span>

				<span class="replacer" transition:fade|local={{duration:200}}>
					<input
						type="file"
						id="azn_i_{name}"
						name="{name}"
						required="{field.required ? 'required' : ''}"
						on:change={handleUpload}
						disabled={field.disabled}
					/>
					<button type="button" class="button" on:click|preventDefault>
						<span>Replace file</span>
					</button>
				</span>

			{:else}

				{#if !uploading}
					<span class="uploader" transition:fade|local={{duration:200}}>
						<input
							type="file"
							id="azn_i_{name}"
							name="{name}"
							required="{field.required ? 'required' : ''}"
							on:change={handleUpload}
							disabled={field.disabled}
						/>
						<svg viewBox="0 0 100 100"><path d="M50 0c27.57 0 50 22.43 50 50s-22.43 50-50 50-50-22.43-50-50 22.43-50 50-50zm3.33 80.68v-49.11l12.51 12.49 4.46-4.47-20.3-20.27-19.4 19.37 4.46 4.47 11.61-11.59v49.11h6.66z"/></svg>
					</span>
				{/if}

			{/if}
		{/if}

	</div>

{/if}