<script>

	// 03 Jun 2024 -- The field value isn't set until it's validated

	import { getServerData } from '../../prelude.js';
	import { getAmounts } from '../../amounts.js';
	import { formToken, displayLang, screens, currentScreen } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let field;
	export let responseSet;

	let name = field.name ? field.name : field.ref;

	let inputmode = 'text';
	let debouncer;

	let value = field.value;

	if (!field.validationCallback) {
		field.validationCallback = async (event) => {

			if (event && event.type == 'input') {
				return true;
			}

			if (value) {

				clearTimeout(debouncer);

				if (event) {
					debouncer = setTimeout(async () => {
						let errors = await validateMembershipNumber();
						return errors;
					}, 200);
				} else {
					// Immediately act on submit
					let errors = await validateMembershipNumber();
					return errors;
				}

			}

			field.validationStatus = null;

			return true;

		};
	}

	async function validateMembershipNumber() {

		let validateEmail = false;
		let emailToValidate = '';

		if (field.validateEmail && field.validateEmail[0]) {
			validateEmail = true;
			if ($currentScreen == '1') {
				for (const c of responseSet.components) {
					if (c.hook && (c.hook == 'e')) {
						emailToValidate = c.value;
						break;
					}
				}
			} else {
				scr: for (const s of [0,2]) {
					if ($screens[s].responseSets) {
						for (const r of $screens[s].responseSets) {
							for (const c of r.components) {
								if (c.hook && (c.hook == 'e')) {
									emailToValidate = c.value;
									break scr;
								}
							}
						}
					}
				}
			}
		}
		
		const validationData = await getServerData('/sites/registrations/membershipnumber', {
			formToken: $formToken,
			fieldRef: field.crmValidation,
			membershipNumber: value,
			validateEmail: validateEmail,
			emailToValidate: emailToValidate
		});

		// console.log({validationData});

		if (validationData && validationData.crmRef) {

			field.validationStatus = {
				type: "success",
				message: "Accepted"
			};

			// field.validationStatus = null;

			field.value = value;

			await getAmounts();

			return true;

		} else {

			// TODO: i18n
			field.validationStatus = {
				type: "error",
				message: "Sorry, we could not validate this ID"
			};

			field.value = '';

			await getAmounts();

			return false;

		}

	}

</script>

<div>

	<input
		type="text"
		id="azn_i_{name}"
		{name}
		bind:value="{value}"
		placeholder="{getTextFromDict(field.placeholder, $displayLang)}"
		required="{field.required ? 'required' : ''}"
		{inputmode}
		on:input
		on:blur
		disabled={field.disabled}
	/>

</div>
