<script>

	import { tick } from 'svelte';

	import Timer from './Timer.svelte';

	import { screens, currentScreen, boxHeight, timeoutRelease, attendee } from '../../stores.js';
	import { sleep } from '../../utils.js';
	
	// export let component;

	let disabled = (($currentScreen == 0) || ($screens.length == ($currentScreen + 1)) || ($screens[$currentScreen - 1].commit)) ? true : false;

	async function changeScreen(s) {
		if (!disabled) {
			if (s < $currentScreen) {
				await setBoxHeight();
				$currentScreen = s;
			}
		}
	}

	async function setBoxHeight() {
		// await tick;
		await sleep(400);
		let h = document.getElementById('aznr_app_inner').offsetHeight;
		$boxHeight = h + 'px';
		// console.log($boxHeight);
	}

</script>

{#if !$attendee}
	<div>
		<!-- {#if component.presentation && component.presentation == 'simplified'}
			<p class="progress simplified">
				<button disabled="{disabled}" class:disabled on:click|preventDefault={changeScreen($currentScreen - 1)}><svg viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg></button>
				<span>Step <strong>{$currentScreen + 1}</strong> of <strong>{$screens.length - 1}</strong></span>
			</p>
		{:else if component.presentation && component.presentation == 'buttons'}
			<div class="progress buttons stage{$currentScreen+1}">
				<ul>
					{#each $screens.slice(0,3) as s}
						<li class="screen{s.num}" class:current={$currentScreen == s.num - 1} on:click={changeScreen(s.num - 1)}>
							<span>{s.name}</span>
							<svg viewBox="0 0 44.99 91.37" preserveAspectRatio="none"><path d="M.35.35l43.29 43.29c1.13 1.13 1.13 2.96 0 4.09l-43.29 43.29"/></svg>
						</li>
					{/each}
				</ul>
			</div>
		{:else} -->
			<div class="progress track stage{$currentScreen+1}">
				<ul>
					{#each $screens.slice(0,3) as s}
						<li class="screen{s.num}" class:current={$currentScreen == s.num - 1} on:click={changeScreen(s.num - 1)}>
							<span class="num">{s.num}</span>
							<span class="label">{s.name}</span>
						</li>
					{/each}
				</ul>
			</div>
		<!-- {/if} -->

		{#if $timeoutRelease}
			<Timer/>
		{/if}
	</div>
{/if}