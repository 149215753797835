<script>

	import { onMount } from 'svelte';

	import { getServerData } from '../../prelude.js';
	import { displayLang, socialMediaServiceOpts } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	import isURL from 'validator/es/lib/isURL';

	export let field;

	let name = field.name ? field.name : field.ref;

	let service;

	async function setService() {
		for (const s of $socialMediaServiceOpts) {
			if (s.name == field.socialMediaService) {
				service = s;
				break;
			}
		}
	}

	let mounted = false;

	onMount(async () => {
		if (!$socialMediaServiceOpts.length) {
			const data = await getServerData('/sites/socialmediaservices');
			socialMediaServiceOpts.set(data.socialmediaservices);
		}
		mounted = true;
	});

	$: if (mounted && field) {
		setService();
	}

	$: if (service) {
		setValidation();
	}

	function setValidation() {

		if (field.validationCallback) {
			return;
		}

		const validate = async (event) => {

			if (event && event.type == 'input') {
				return true;
			}

			if (field.value) {
				if (!isURL(field.value, { protocols: ['http','https'], host_whitelist: service.domains })) {
					const message = 'Please enter a valid ' + service.name + ' URL.'; // TODO: i18n
					field.validationStatus = {
						type: "error",
						message: message
					};
					return false;
				}
			}

		};

		field.validationCallback = validate;

	}

	if (!field.submitCallback) {
		field.submitCallback = async () => {
			if (field.value) {
				if (!/^(?:f|ht)tps?\:\/\//.test(field.value)) {
					field.value = "http://" + field.value;
				}
			}
		};
	}

</script>

{#if mounted}

	<div>
		{#if service}
			<span class="icon" style="--serviceColor: {service.color}">{@html service.icon}</span>
		{/if}

		<input
			type="text"
			id="azn_i_{name}"
			{name}
			bind:value="{field.value}"
			placeholder="{getTextFromDict(field.placeholder,$displayLang)}"
			required="{field.required ? 'required' : ''}"
			inputmode="url"
			on:input
			on:blur
			disabled={field.disabled}
		/>
	</div>

{/if}