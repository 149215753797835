<script>

	import { slide } from 'svelte/transition';

	import { displayLang, event, sitemap, screens, ticketTypes } from '../stores.js';
	import { getTextFromDict } from '../i18n.js';

	import TextField from './fields/TextField.svelte';
	import TextArea from './fields/TextArea.svelte';
	import SelectList from './fields/SelectList.svelte';
	// import TicketType from './fields/TicketType.svelte';
	import FileField from './fields/FileField.svelte';
	import DateField from './fields/DateField.svelte';
	import Checkbox from './fields/Checkbox.svelte';
	import CheckboxGroup from './fields/CheckboxGroup.svelte';
	import RadioButtons from './fields/RadioButtons.svelte';
	import SocialMediaUrlField from './fields/SocialMediaUrlField.svelte';
	import EmailField from './fields/EmailField.svelte';
	import UrlField from './fields/UrlField.svelte';
	import PhoneField from './fields/PhoneField.svelte';
	import NumberField from './fields/NumberField.svelte';
	import DiscountCode from './fields/DiscountCode.svelte';
	import PaymentOptions from './fields/PaymentOptions.svelte';
	import TaxID from './fields/TaxID.svelte';
	import MembershipNumber from './fields/MembershipNumber.svelte';

	let componentLookup = {
		TextField: 					TextField,
		EmailField: 				EmailField,
		UrlField: 					UrlField,
		TextArea: 					TextArea,
		SelectList: 				SelectList,
		// TicketType: 				TicketType,
		DiscountCode:				DiscountCode,
		MembershipNumber: 		TextField,
		Country: 					SelectList,
		ImageUpload: 				FileField,
		DateField: 	 				DateField,
		Checkbox: 					Checkbox,
		CheckboxGroup:				CheckboxGroup,
		RadioButtons: 				RadioButtons,
		PhoneField:					PhoneField,
		NumberField:				NumberField,
		SocialMediaUrlField:		SocialMediaUrlField,
		PaymentOptions:	   	PaymentOptions,
		TaxID: 						TaxID,
		MembershipNumber: 		MembershipNumber,
	};

	export let field;
	export let responseSet;

	let name = field.name ? field.name : field.ref;

	let labelText = getTextFromDict(field.label, $displayLang);

	function fixLink(href) {;
		const regex = new RegExp(`azn://page/(.*)`);
		href = href.replace(regex, function (string, capture) {
			for (const p of $sitemap) {
				if (p.ref == capture) {
					return $event.url + p.path;
				}
			}
			return string;
		});
		return href;
	}

</script>

<div class="azn_block" id="azn_f_{name}" class:disabled={field.disabled}>

	<div class="azn_labelgroup">
		{#if field.label && (field.component != 'Checkbox')}
			<!-- <label for="azn_i_{name}"><strong>{labelText}{labelText.match(/[\w\)]$/) ? ':' : ''}</strong></label> -->
			<label for="azn_i_{name}"><strong>{labelText}</strong></label>
			{#if field.required}
				<span class="azn_required">
					<svg viewBox="0 0 47.58 50">
						<title>{field.required}</title>
						<path d="M20.32 30.9l-16.15 10.25-4.17-7.29 17.02-8.86-17.01-8.85 4.17-7.29 16.14 10.24-.7-19.1h8.33l-.69 19.1 16.15-10.24 4.17 7.29-17.02 8.85 17.01 8.85-4.17 7.29-16.14-10.24.7 19.1h-8.33l.69-19.1z"/>
					</svg>
					<!-- <svg viewBox="0 0 46.62 50">
						<title>{field.required}</title>
						<path d="M20.53 29.73l-17.31 10.59-3.21-5.64 17.87-9.68-17.88-9.68 3.21-5.64 17.32 10.59-.48-20.27h6.51l-.48 20.27 17.32-10.59 3.21 5.64-17.87 9.68 17.88 9.68-3.21 5.64-17.32-10.59.48 20.27h-6.51l.47-20.27z"/>
					</svg> -->
				</span>
			{/if}
		{/if}
	</div>

	<div class="azn_inputgroup">

		{#if field.component == 'MembershipNumber'}
			<svelte:component this={componentLookup[field.component]} bind:field={field} {responseSet} on:input on:change on:blur on:set/>
		{:else}
			<svelte:component this={componentLookup[field.component]} bind:field={field} on:input on:change on:blur on:set/>
		{/if}

		{#if field.notes && getTextFromDict(field.notes,$displayLang)}
			<p class="azn_notes">
				<svg viewBox="0 0 56 56"><path d="M56 28c0 15.46-12.54 28-28 28s-28-12.54-28-28 12.54-28 28-28 28 12.54 28 28zm-30.09-13.59c-.08 2.4 1.29 3.85 4.18 3.85 3.45 0 5.47-2.34 5.47-4.86 0-2.02-.9-3.79-3.95-3.79-4.01 0-5.7 2.78-5.7 4.8zm10.49 25.16l-1.18-1.58c-2.01 1.59-4.06 2.34-4.69 2.34-.49 0-.69-.63-.21-2.46l2.74-10.36c1.04-3.79.69-6.26-1.39-6.26-2.49 0-8.32 2.48-13.38 6.71l1.04 1.7c1.6-1.08 4.3-2.16 4.93-2.16.48 0 .41.63 0 2.21l-2.39 9.86c-1.46 5.55.07 6.82 2.15 6.82 2.07 0 7.45-1.89 12.38-6.82z"/></svg>
				{#if field.notesHref}
					<a href={fixLink(field.notesHref)} target="_blank">{getTextFromDict(field.notes,$displayLang)}</a>
				{:else}
					<span>{getTextFromDict(field.notes,$displayLang)}</span>
				{/if}
			</p>
		{/if}

		{#if field.validationStatus && field.validationStatus.message}
			<p class="azn_notes {field.validationStatus.type}" transition:slide|local>
				{#if field.validationStatus.type == 'success'}
					<svg viewBox="0 0 60 60"><path d="M22.7 44.5l-10.8-10.8 3.8-3.7 7 7 21.6-21.5 3.8 3.7z"/><path d="M30 60c-16.5 0-30-13.5-30-30s13.5-30 30-30 30 13.5 30 30-13.5 30-30 30zm0-56c-14.3 0-26 11.7-26 26s11.7 26 26 26 26-11.7 26-26-11.7-26-26-26z"/></svg>
				{/if}
				<span>{field.validationStatus.message}</span>
			</p>
		{/if}

	</div>

</div>