<script>

	import { onMount, onDestroy, createEventDispatcher, getContext } from 'svelte';

	import dayjs from "dayjs";
	import utc from "dayjs/plugin/utc";
	dayjs.extend(utc);

	import RadioButton from '../../ui/RadioButton.svelte';

	import { ticketTypes, displayLang, event, invitee, bigError } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	const dispatch = createEventDispatcher();
	const checkinAttendee = getContext('checkinAttendee');

	export let field;

	// console.log('TicketType field', field);

	let name = field.name ? field.name : field.ref;

	let waitlistErrorShown = false;

	function getAmountString(amount) {
		let n = Number(amount);
		if (n) {
			return n.toLocaleString($event.locale, {style:'currency', currency:$event.currency});
		} else {
			return '';
		}
	}

	function setTicketType(o) {
		if (isAvailable(o)) {
			field.value = o.ref;
			dispatch('set');
		}
	}

	if (!field.submitCallback) {
		field.submitCallback = async () => {
			if (field.value) {
				for (const o of $ticketTypes) {
					if (o.ref == field.value) {
						if (o.earlybirdPrice && o.earlybirdDeadline && !o.earlybirdExpired) {
							if (!isYetToCome(o.earlybirdDeadline)) {
								$bigError = 'Price';
								return false;
							}
						}
						return true;
					}
				}
			}
			return true;
		};
	}

	function isYetToCome(date) {
		let dt = dayjs.utc(date);
		let now = dayjs.utc();
		return dt.isAfter(now) ? true : false;
	}

	function isAvailable(o) {
		return (
			(($event.available == null) || ($event.available))
			&& (!o.registrationExpired	&& (!o.soldOut || o.waitlist))
			&& (!checkinAttendee || (!o.validFrom || !isYetToCome(o.validFrom)) || (!o.validTo || !isYetToCome(o.validTo)))
		) ? true : false;
	}

	let visibleTickets = [];

	onMount(() => {

		window.addEventListener("hashchange", checkHash, false);
		checkHash();

		for (const o of $ticketTypes) {
			if ($invitee) {
				if ($invitee.ticketTypes && $invitee.ticketTypes.includes(o.ref)) {
					visibleTickets.push(o);
				}
			} else {
				if (!o.invitationOnly && (checkinAttendee || !o.onsiteOnly)) {
					visibleTickets.push(o);
				}
			}
		}

		let hasTickets = false;

		// console.log("Event available", $event.available);

		for (const o of visibleTickets) {
			// console.log('Ticket', o);
			if (isAvailable(o)) {
				hasTickets = true;
				break;
			}
		}

		if (!hasTickets) {
			$bigError = 'Tickets';
		}

		if ((visibleTickets.length == 1) && hasTickets) {
			field.value = visibleTickets[0].ref;
		}

	});

	onDestroy(() => {
		window.removeEventListener("hashchange", checkHash, false);
	});

	function checkHash() {
		let hash = location.hash;
		let hashItems = hash.split('/');
		// console.log(hashItems[1],hashItems[2],hashItems[3]);
		if ((hashItems[1] == 'azn') && (hashItems[2] == 't') && (hashItems[3])) {
			for (const o of $ticketTypes) {
				if (o.ref.replace(/-/g, '') == hashItems[3]) {
					if (!o.invitationOnly) { // you can't access an invitation-only ticket type this way; only via a valid invitation
						setTicketType(o);
					}
					break;
				}
			}
		}
	}

	$: if (field.value) {
		checkWaitlist();
	}

	function checkWaitlist() {
		if (waitlistErrorShown) return;
		for (const o of $ticketTypes) {
			if (o.ref == field.value) {
				if	($event.available && !o.registrationExpired && o.soldOut && o.waitlist) {
					$bigError = 'Will waitlist';
					waitlistErrorShown = true;
				}
			}
		}
	}

</script>

<div class="azn_block" id="azn_f_{name}" class:disabled={field.disabled}>
	<div class="azn_inputgroup">
		<div class="radiobuttons {field.presentation}">
			{#each visibleTickets as o}
				<p class="{field.value == o.ref ? 'on' : 'off'}" class:disabled={!isAvailable(o) || field.disabled}>
					<RadioButton>
						<input type="radio" id="azn_i_{field.ref}_{o.ref}" name={field.ref} bind:group={field.value} value={o.ref} disabled={!isAvailable(o) || field.disabled}/>
					</RadioButton>
					<label for="azn_i_{field.ref}_{o.ref}">
						<strong>{getTextFromDict(o.name,$displayLang)}</strong>
						{#if isAvailable(o) || (field.value == o.ref)}
							{#if !(field.showPrices == 'hide')}
								{#if o.earlybirdPrice && !o.earlybirdExpired}
									<span>{getAmountString(o.earlybirdPrice)}</span>
								{:else if o.price}
									<span>{getAmountString(o.price)}</span>
								{/if}
							{/if}
						{:else}
							<span>Not available</span>
						{/if}
					</label>
				</p>
			{/each}
		</div>
	</div>
</div>