<script>
  export let size = 25;
  export let speed = 750;
  export let thickness = 2;
  export let gap = 40;
  export let radius = 10;

  let dash;
  $: dash = 2 * Math.PI * radius * (100 - gap) / 100
</script>

<!-- <style>
  :global(#aznr_app) .azn_spinner {
    text-align: center;
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    margin: 0;
    line-height: 1;
    display: grid;
    place-content: center;
  }
  .azn_spinner_svg {
    transition-property: transform;
    animation-name: svelte-spinner_infinite-spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  circle {
    stroke: var(--linkColor, #000);
  }
  @keyframes svelte-spinner_infinite-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
</style> -->

<p class="azn_spinner">
  <svg
    height="{size}"
    width="{size}"
    style="animation-duration:{speed}ms;"
    class="azn_spinner_svg"
    viewbox="0 0 32 32"
  >
    <circle
      role="presentation"
      cx="16"
      cy="16"
      r="{radius}"
      fill="none"
      stroke-width="{thickness}"
      stroke-dasharray="{dash},100"
      stroke-linecap="round"
    />
  </svg>
</p>
