// import { sleep } from './utils.js';

export const responseRequired = (component) => {
	if (component.shown && component.required && ((component.value == null) || (Array.isArray(component.value) && !component.value.length) || (component.value == ''))) {
		return true;
	} else {
		return false;
	}
};

export const validateField = async (component, event) => {

	let ok = true;

	// await sleep(1000);

	if (component.value && (typeof component.value == 'string') && !(event && (event.type == 'input'))) {
		component.value = component.value.trim();
	}

	if (component.validationCallback) {
		ok = await component.validationCallback(event);
	}

	if (ok) {
		if (responseRequired(component)) {
			const message = (event && (event.type == 'input')) ? null : 'This field is required'; // TODO: i18n
			component.validationStatus = {
				type: "error",
				message: message
			};
			ok = false;
		}
	}

	if (ok && !event) {
		// console.log('evaluating submitCallback (' + component.component + ')...');
		if (component.submitCallback) {
			ok = await component.submitCallback(event);
		}
	}

	if (ok) {
		component.validationStatus = {
			type: "success"
		}
	}

};