<script>

	import { onMount, onDestroy, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';
	
	import { goHome } from '../utils.js';

	const dispatch = createEventDispatcher();

	export let inviteeResponse;

	let originalBodyOverflow = document.body.style.overflow;

	onMount(() => {
		document.body.style.overflow = 'hidden';
	});

	onDestroy(() => {
		document.body.style.overflow = originalBodyOverflow;
	});

</script>

<div class="BigError" in:fade={{ duration:200, delay:100 }}>
	<div>

		{#if inviteeResponse == 'Maybe'}

			<p><strong>Shall we put you down as a maybe?</strong></p>
			<p class="buttons">
				<button type="button" class="button" on:click={() => { dispatch('maybe') }}>I might attend</button>
			</p>

		{:else if inviteeResponse == 'Maybe-Confirmed'}

			<p><strong>Thanks for letting us know. We hope you can make it.</strong></p>
			<p>When you’re ready to confirm please click the link in your invitation.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{:else if inviteeResponse == 'Decline'}

			<p><strong>Please confirm that you can’t attend this event.</strong></p>
			<p class="buttons">
				<button type="button" class="button" on:click={() => { dispatch('decline') }}>I can’t attend</button>
			</p>

		{:else if inviteeResponse == 'Decline-Confirmed'}

			<p><strong>Thanks for letting us know. Sorry you can’t make it this time.</strong></p>
			<p>We hope to see you at a future event soon.</p>
			<p class="buttons">
				<button type="button" class="button" on:click={goHome}>OK</button>
			</p>

		{/if}

	</div>
</div>
