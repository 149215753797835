<script>

	import Checkbox from '../../ui/Checkbox.svelte';

	import { crmFields, displayLang } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let field;

	let name = field.name ? field.name : field.ref;

	function setOptions() {
		if (!field.options) {
			if (field.crm) {
				for (const c of $crmFields) {
					if (c.ref == field.crm) {
						field.options = c.options;
						break;
					}
				}
			}
		}
	}

	function setValueFromDefault() {
		if (!field.value) {
			field.value = field.default ? field.default : '';
		}
	}

	$: if (field) {
		setOptions();
		setValueFromDefault();
	}

</script>

<!-- <style>
	p {
		margin: 10px 0;
		padding-left: 30px;
		position: relative;
	}
	input {
		position: absolute;
		top: 0;
		left: 1px;
	}
</style> -->

{#if field.options}
	<div class="checkboxes">
		{#each field.options as o}
			<p>
				<Checkbox>
					<input
						type="checkbox"
						id="azn_i_{name}_{o.ref}"
						name={name}
						bind:group={field.value}
						value={o.ref}
						required="{field.required ? 'required' : ''}"
						on:change
						on:blur
						disabled={field.disabled}
					>
				</Checkbox>
				<label for="azn_i_{name}_{o.ref}">{getTextFromDict(o.name, $displayLang)}</label>
			</p>
		{/each}
	</div>
{/if}
