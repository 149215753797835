<script>

	import Checkbox from '../../ui/Checkbox.svelte';

	import { crmFields, displayLang } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let field;

	let name = field.name ? field.name : field.ref;

	$: if (field.value === undefined) {
		field.value = '';
	}

</script>

<!-- <style>
	:global(.azn_mid) .checkboxes {
		margin-right: calc(0px - var(--rhcw));
	}
</style> -->

<div class="checkboxes">
	<p>
		<Checkbox>
			<input
				type="checkbox"
				id="azn_i_{name}_o"
				name={name}
				bind:group={field.value}
				value="true"
				required="{field.required ? 'required' : ''}"
				on:change
				on:blur
				disabled={field.disabled}
			>
		</Checkbox>
		<label for="azn_i_{name}_o">{getTextFromDict(field.label, $displayLang)}</label>
		{#if field.required}
			<span class="azn_required">
				<svg viewBox="0 0 47.58 50">
					<title>{field.required}</title>
					<path d="M20.32 30.9l-16.15 10.25-4.17-7.29 17.02-8.86-17.01-8.85 4.17-7.29 16.14 10.24-.7-19.1h8.33l-.69 19.1 16.15-10.24 4.17 7.29-17.02 8.85 17.01 8.85-4.17 7.29-16.14-10.24.7 19.1h-8.33l.69-19.1z"/>
				</svg>
				<!-- <svg viewBox="0 0 46.62 50">
					<title>{field.required}</title>
					<path d="M20.53 29.73l-17.31 10.59-3.21-5.64 17.87-9.68-17.88-9.68 3.21-5.64 17.32 10.59-.48-20.27h6.51l-.48 20.27 17.32-10.59 3.21 5.64-17.87 9.68 17.88 9.68-3.21 5.64-17.32-10.59.48 20.27h-6.51l.47-20.27z"/>
				</svg> -->
			</span>
		{/if}
	</p>
</div>
