import { get } from 'svelte/store';

import { postServerData } from './prelude.js';
import { discountCode, taxID, bookingCountry, bookingSCP, bookingPostcode, formToken, displayLang, displayAmounts, screens } from './stores.js';

export const getAmounts = async () => {

	const dc = get(discountCode);
	const td = get(taxID);
	const bc = get(bookingCountry);
	const bs = get(bookingSCP);
	const bp = get(bookingPostcode);

	const ft = get(formToken);
	const dl = get(displayLang);

	const scr = get(screens);
	const json = JSON.stringify({ screens: scr });

	// console.log("Checking amounts",dc,td,bc,bs,bp);

	const amountsData = await postServerData('/sites/registrations/amounts', {
		discountCode: dc,
		taxID: td,
		country: bc,
		scp: bs,
		postcode: bp,
		formToken: ft,
		lang: dl,
		formData: json
	});

	console.log({amountsData});

	if (amountsData.displayAmounts) {
		displayAmounts.set(amountsData.displayAmounts);
	}

}