<script>

	// 03 Jun 2024 -- The field value isn't set until it's validated

	import { getAmounts } from '../../amounts.js';
	import { displayLang, displayAmounts, amountsDebounce, discountCode } from '../../stores.js';
	import { getTextFromDict } from '../../i18n.js';

	export let field;

	let name = field.name ? field.name : field.ref;

	let inputmode = 'text';

	let value = field.value;

	if (!field.validationCallback) {
		field.validationCallback = async (event) => {

			console.log('dc callback', event, value);

			if (event && event.type == 'input') {
				return true;
			}

			if (value) {

				clearTimeout($amountsDebounce);

				$discountCode = value;

				console.log('Setting discountCode', $discountCode);

				if (event) {
					$amountsDebounce = setTimeout(async () => {
						let errors = await validateDiscountCode();
						return errors;
					}, 200);
				} else {
					// Immediately act on submit
					let errors = await validateDiscountCode();
					return errors;
				}

			}

			field.validationStatus = null;

			return true;

		};
	}

	async function validateDiscountCode() {
		
		await getAmounts();

		if ($displayAmounts.discountPercentage) {

			const message = 'A discount of ' + $displayAmounts.discountPercentage + '% has been applied'; // TODO: i18n

			field.validationStatus = {
				type: "success",
				message: message
			};

			field.value = value;

			return null;

		} else {

			// TODO: i18n
			field.validationStatus = {
				type: "error",
				message: "Not valid for this event"
			};

			$discountCode = '';
			field.value = '';

			return false;

		}

	}

</script>

<div>

	<input
		type="text"
		id="azn_i_{name}"
		{name}
		bind:value="{value}"
		placeholder="{getTextFromDict(field.placeholder, $displayLang)}"
		required="{field.required ? 'required' : ''}"
		{inputmode}
		on:input
		on:blur
		disabled={field.disabled}
	/>

	<!-- 08 Sep 2021 - placebo button (it'll blur the field, which is all we need to actually apply...) -->
	<span><button type="button" class="button" on:click|preventDefault={(e) => {e.target.blur()}}><span>Apply</span></button></span>

</div>
