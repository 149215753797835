import { get } from 'svelte/store';

import axios from "axios";

// Regform in dev only works for events without a domain
// But dev events with a domain don't work anyway

// const baseURL = (window.location.hostname.endsWith('.sites-dev.attendzen.io') || !window.location.hostname) ? 'https://api-dev.attendzen.io/' : 'https://api.attendzen.io/';

// console.log('prelude', window.location.hostname, window.parent.location.hostname, baseURL);

const baseURL = (window.location.hostname.endsWith('.sites-dev.attendzen.io') || (window.parent.location.hostname == 'platform-dev.attendzen.io') || (window.parent.location.hostname == 'localhost')) ? 'https://api-dev.attendzen.io/' : 'https://api.attendzen.io/';

const debug = false;
const refreshDebug = false;

import { eventToken, formToken, bigError } from './stores.js';

const prelude = axios.create({
	baseURL: baseURL,
	timeout: 100000,
	withCredentials: true
});

export const getServerData = async (endpoint, params) => {

	try {

		if (debug) console.log('GET', endpoint, new Date().toISOString());

		let headers = {};
		const authToken = sessionStorage.getItem('aznrAuthToken');
		if (authToken) headers['Authorization'] = 'Bearer ' + authToken;

		const response = await prelude({
			url: endpoint,
			method: "GET",
			headers: headers,
			params: params
		});

		if (debug) console.log('data', 'GET', endpoint, response.data, new Date().toISOString());

		if (response.data.error) {
			bigError.set(response.data.error);
		}

		return response.data;

	} catch (err) {

		if (debug) console.log('prelude error', err.response.status);
		bigError.set("There was an error communicating with the registration service.");

	}

}

export const postServerData = async (endpoint, params) => {

	let formData = new FormData();

	if (params) {
		for (let [key, value] of Object.entries(params)) {
			formData.append(key, value);
		}
	}

	try {

		if (debug) console.log('POST', endpoint, new Date().toISOString());

		let headers = {};
		const authToken = sessionStorage.getItem('aznrAuthToken');
		if (authToken) headers['Authorization'] = 'Bearer ' + authToken;

		const response = await prelude({
			url: endpoint,
			method: "POST",
			headers: headers,
			data: formData
		});

		if (debug) console.log('data', 'POST', endpoint, response.data, new Date().toISOString());

		if (response.data.error) {
			bigError.set(response.data.error);
		}

		return response.data;

	} catch (err) {

		if (debug) console.log('prelude error', err.response.status);
		bigError.set("There was an error communicating with the registration service.");

	}

}

export const getAuth = async () => {

	const token = get(eventToken);

	try {

		const endpoint = '/sites/registrations/auth/' + token;

		if (debug) console.log('POST', endpoint, new Date().toISOString());

		const response = await prelude({
			url: endpoint,
			method: "POST"
		});

		if (debug) console.log('data', 'POST', endpoint, response.data, new Date().toISOString());

		sessionStorage.setItem('aznrAuthToken', response.data.authToken);

		// token expires in 2 days; refresh after 1
		setTimeout(getAuth, 1000 * 60 * 60 * 24);

		return true;

	} catch (err) {

		if (debug) console.log('prelude error', err.response.status);
		bigError.set("There was an error communicating with the registration service.");

	}

}